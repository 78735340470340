import React from 'react';

class Callback extends React.Component {
    render() {
        return (
            <div>
                {/* Your component's content goes here */}
            </div>
        );
    }
}

export default Callback;