import React, { useState, useEffect } from "react";
import 'react-modern-drawer/dist/index.css';
import logo from '../../images/logo.png';
import { IoIosArrowDown } from "react-icons/io";

import macbook_air from '../../images/macbook_air.png';
import iphones from '../../images/iphones.png';

import Group468 from '../../images/partners/Group468.png';
import Group520 from '../../images/partners/Group520.png';
import Group526 from '../../images/partners/Group526.png';
import Group527 from '../../images/partners/Group527.png';
import Group594 from '../../images/partners/Group594.png';
import Group609 from '../../images/partners/Group609.png';

import innovate from '../../images/innovate.png';

import skylark_bg from '../../images/skylark_bg.png';

import about_background from '../../images/about_background.png';

const AboutPanel = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Helper function to get the appropriate class name for tab content
    const getTabContentClassName = (tabIndex) => {
        return activeTab === tabIndex ? "tabContentActive" : "tabContent";
    };

    // Helper function to get the appropriate class name for the arrow
    const getArrowClassName = (tabIndex) => {
        return activeTab === tabIndex ? "arrow_down arrow_rotate" : "arrow_down";
    };

    const togglenewAboutHeaderHidden = () => {
        const newAboutHeaderHidden = document.querySelector('.newAboutHeaderHidden');
        newAboutHeaderHidden.classList.toggle('newAboutHeaderHiddenActive');
        newAboutHeaderHidden.style.transition = 'all 0.5s ease';
    }

    useEffect(() => {

        let newAboutHeaderInfgraphic = document.querySelector('.newAboutHeaderInfgraphic');
        newAboutHeaderInfgraphic.style.backgroundImage = `url(${skylark_bg})`;

        const scrollContainer = document.querySelector('.newAboutHeaderInfgraphicScroller');
        console.log(scrollContainer);
        if (scrollContainer) {
            scrollContainer.addEventListener('wheel', handleWheel);
        } else {
            console.error('Element with class .newAboutHeaderInfgraphic not found');
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    const handleWheel = (e) => {
        const scrollContainer = e.currentTarget;
        const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
        if (e.deltaY !== 0) {
            if (scrollContainer.scrollLeft === 0 && e.deltaY < 0) {
                // Allow default vertical scrolling when at the end of the horizontal scroll
                console.log('scrolling vertically at start');
                // scrollContainer.style.pointerEvents = 'none';
                return;
            }
            if (scrollContainer.scrollLeft === maxScrollLeft) {
                if (e.deltaY > 0) {
                    return;
                }
            }
            e.preventDefault();
            scrollContainer.scrollLeft += e.deltaY;
        }
    };

    return (
        <div className='about'>
            <div className="aboutPageScroller">
                <div className="newAboutHeader">
                    <div className="newAboutHeaderBackground">
                        <img src={about_background} alt="about_background" />
                    </div>
                    <div className="newAboutHeaderTitleIcon">
                        <img src={logo} alt="logo" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="194" height="67" viewBox="0 0 194 67">
                            <text id="Skylark" transform="translate(97 53)" fill="#fff" font-size="56" font-family="Figtree-ExtraBold, Figtree" font-weight="800" letter-spacing="-0.01em"><tspan x="-96.936" y="0">Skylark</tspan></text>
                        </svg>
                    </div>
                    <div className="newAboutHeaderTitle"><h1>Skylark Land Tech</h1></div>
                    <div className="newAboutHeaderSubTitle"><h2>The solution for integrating
                        data, science and innovation
                        for better use of land.</h2></div>
                    <div className="newAboutHeaderTitleButton">
                        <button onClick={togglenewAboutHeaderHidden}>
                            More
                        </button>
                    </div>
                </div>
                {/*  */}
                <div className="newAboutHeaderHidden">
                    <div className="newAboutHeaderHiddenTitle">
                        <h1>About Skylark</h1>
                    </div>
                    <div className="newAboutHeaderHiddenSubTitle">
                        <div className="newAboutHeaderHiddenSubTitleText">
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore
                                magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim.</p>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="newAboutHeaderInfo">
                    <div className="newAboutHeaderInfoTitle"><h1>Commercial Application</h1></div>
                    <div className="newAboutHeaderInfoSubTitle">There is substantial demand for Land Tech solutions.</div>
                    <div class="newAboutHeaderInfocontainer">
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Natural Capital</h2></div>
                            <div className="boxSubTitle">
                                <p>Contextualise negotiations. Identify opportunities
                                    for trading and leveraging Natural Capital.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Environmental Services</h2></div>
                            <div className="boxSubTitle">
                                <p>Unlocking potential in established and maturing markets.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Profit</h2></div>
                            <div className="boxSubTitle">
                                <p>Maximise profit at a high-and granular-level.
                                    Understand compromise against other metrics.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Risk</h2></div>
                            <div className="boxSubTitle">
                                <p>Data-driven insights such as environmental
                                    concerns or market fluctuations.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Sustainability</h2></div>
                            <div className="boxSubTitle">
                                <p>Identify opportunities to reduce environmental footprint.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Decision-Making</h2></div>
                            <div className="boxSubTitle">
                                <p>Evaluate potential outcomes and assess the impact
                                    of specific measures</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Transparency</h2></div>
                            <div className="boxSubTitle">
                                <p>Build trust among stakeholders and create
                                    a more collaborative decision-making process.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Competition</h2></div>
                            <div className="boxSubTitle">
                                <p>Respond to emerging trends in a competitive landscape.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Investment</h2></div>
                            <div className="boxSubTitle">
                                <p>Better assess the potential risks and rewards
                                    associatedwith specific projects.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Efficiency</h2></div>
                            <div className="boxSubTitle">
                                <p>Better-informed decisions and lower costs.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Land</h2> Use Modelling</div>
                            <div className="boxSubTitle">
                                <p>Realise untapped commercial opportunities.</p></div>
                        </div>
                        <div class="box">
                            <div className="boxTitle">
                                <h2>Skylark</h2> Air</div>
                            <div className="boxSubTitle">
                                <p>Ground-truthing strategic data and forecasting. </p></div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="newAboutHeaderInfgraphic">
                    <div className="newAboutHeaderInfgraphicScroller">
                        <div className="newAboutHeaderInfgraphicPackContainer">
                            <div className="newAboutHeaderInfgraphicPackleft">
                                <div className="newAboutHeaderInfgraphicImage">
                                    <img src={macbook_air} alt="macbook_air" />
                                </div>
                                <div className="newAboutHeaderInfgraphicTextRight">
                                    <div className="newAboutHeaderInfgraphicTextRightLogo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="315" height="67" viewBox="0 0 415 67">
                                            <text id="SkylarkAnalytics" transform="translate(0 53)" fill="#fff" font-size="56" font-family="Figtree-ExtraBold, Figtree" font-weight="800" letter-spacing="-0.01em"><tspan x="0" y="0">Skylark</tspan><tspan y="0" font-family="Figtree-Light, Figtree" font-weight="300">Analytics</tspan></text>
                                        </svg>
                                    </div>
                                    <div className="newAboutHeaderInfgraphicTextRightSubheader">
                                        <h1>For Desktop</h1>
                                    </div>
                                    — Informing negotiation on Natural Capital Assets
                                    <br />
                                    — Forecast scenarios and assess the impact for different land uses
                                    <br />
                                    — A single point of reference, drawing simplicity out of complexity
                                    <br />
                                    — Saving time, increasing detail and improving communication
                                    <br />
                                    — 3D interface provides new perspective
                                    <br />
                                    — Unlimited metric analysis (Carbon, BNG and Profit)
                                    <br />
                                    — Forecast, save and present
                                    <br />
                                    — Modify Land Parcels
                                    <br />
                                </div>
                            </div>
                            <div className="newAboutHeaderInfgraphicPackright">
                                <div className="newAboutHeaderInfgraphicImage">
                                    <img src={iphones} alt="iphones" />
                                </div>
                                <div className="newAboutHeaderInfgraphicTextRight">
                                    <div className="newAboutHeaderInfgraphicTextRightLogo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="315" height="67" viewBox="0 0 415 67">
                                            <text id="SkylarkAnalytics" transform="translate(0 53)" fill="#fff" font-size="56" font-family="Figtree-ExtraBold, Figtree" font-weight="800" letter-spacing="-0.01em"><tspan x="0" y="0">Skylark</tspan><tspan y="0" font-family="Figtree-Light, Figtree" font-weight="300">Analytics</tspan></text>
                                        </svg>
                                    </div>
                                    <div className="newAboutHeaderInfgraphicTextRightSubheader">
                                        <h1>For Mobile</h1>
                                    </div>
                                    — Informing negotiation on Natural Capital Assets
                                    <br />
                                    — Forecast scenarios and assess the impact for different land uses
                                    <br />
                                    — A single point of reference, drawing simplicity out of complexity
                                    <br />
                                    — Saving time, increasing detail and improving communication
                                    <br />
                                    — 3D interface provides new perspective
                                    <br />
                                    — Unlimited metric analysis (Carbon, BNG and Profit)
                                    <br />
                                    — Forecast, save and present
                                    <br />
                                    — Modify Land Parcels
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="newAboutHeaderpartners">
                    <div className="newAboutHeaderpartnersTitle">
                        <h1>
                            Our Partners
                        </h1>
                    </div>
                    <div className="newAboutHeaderpartnersSubTitle">
                        Would like a subheading for this section.
                    </div>
                    <div class="newAboutHeaderpartnerscontainer">
                        <div class="box">
                            <img src={Group468} alt="Group468" />
                        </div>
                        <div class="box">
                            <img src={Group520} alt="Group520" />
                        </div>
                        <div class="box">
                            <img src={Group526} alt="Group526" />
                        </div>
                        <div class="box">
                            <img src={Group527} alt="Group527" />
                        </div>
                        <div class="box">
                            <img src={Group594} alt="Group594" />
                        </div>
                        <div class="box">
                            <img src={Group609} alt="Group609" />
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="newAboutHeadercontact">
                    <div className="newAboutHeadercontactTitle">
                        <h1>Contact</h1>
                    </div>
                    <div className="newAboutHeadercontactSubTitle">
                        <h2>Get in touch with us</h2>
                    </div>
                    <div className="newAboutHeadercontactcontainer">
                        <div className="newAboutHeadercontactSubcontainer">
                            <form>
                                <input placeholder="First Name" className="splitInputLeft" type="text" id="firstName" name="firstName" required />

                                <input placeholder="Last Name" className="splitInputRight" type="text" id="lastName" name="lastName" required />

                                <input placeholder="Email" type="email" id="email" name="email" required />

                                <input placeholder="Business/Company" className="splitInputLeft" type="text" id="company" name="company" required />

                                <input placeholder="Position" className="splitInputRight" type="text" id="position" name="position" required />

                                <select id="reason" name="reason" required>
                                    <option value="">Please select</option>
                                    <option value="General Inquiry">General Inquiry</option>
                                    <option value="Support">Support</option>
                                    <option value="Feedback">Feedback</option>
                                    <option value="Other">Other</option>
                                </select>

                                <textarea placeholder="Message" id="message" name="message" rows="5" required></textarea>

                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="newAboutHeaderfooter">
                    <div className="newAboutHeaderfooterTitle">
                        <img src={innovate} alt="innovate" />
                    </div>
                    <div className="newAboutHeaderfooterSubTitle">© Skylark Land Tech 2024  |  Privacy Policy</div>
                </div>
            </div>
        </div>
    );
}

export default AboutPanel;
