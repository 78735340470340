import React, { useEffect, useState, useRef } from "react";
import 'react-modern-drawer/dist/index.css';
import Chart from "chart.js/auto"; // Import Chart.js
import { presetColors } from '../../../libs/colours.js';
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { TbDeviceFloppy } from "react-icons/tb";
import { PiFolderSimpleUser } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { VscFeedback } from "react-icons/vsc";
import { IoKeyOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { TbFolderQuestion } from "react-icons/tb";
import banner from '../../images/banner.png';
import { auth } from "../../../firebase.js";
import { getAuth, updatePassword, updateProfile } from "firebase/auth";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { listDocuments } from "../../../firebase.js";

const SettingsPanel = ({
    isComparing,
    handlesettingsClose,
    longstandingGeoJSONRef,
    selectedMetric
}) => {

    const [isInTabs, setIsInTabs] = useState(true);

    const [ulist, setUlist] = useState([]);

    const [isInAccountSettings, setIsInAccountSettings] = useState(false);

    const [isMapViewActive, setIsMapViewActive] = useState(false);

    const [feedback, setFeedback] = useState('');

    const openAccountSettings = () => {
        // reset();
        // setIsInAccountSettings(true);
        // setIsInTabs(false);
        window.location.href = 'https://api.staging.skylark.entsys.uk/change';
    }

    // Account Settings

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setdisplayName] = useState("");
    const [user, setUser] = useState(null);
    const [forgotPasswordScreen, setForgotPasswordScreen] = useState(true);
    const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(true);
    const [incorrectPassword, setIncorrectPassword] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                console.log(authUser.displayName);
                setdisplayName(authUser.displayName);
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState("");

    const editDisplayName = () => {
        setIsEditingDisplayName(true);
    }

    const editDoneDisplayName = () => {
        setIsEditingDisplayName(false);
    }

    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");

    const editPassword = () => {
        setIsEditingPassword(true);
    }

    const editDonePassword = () => {
        setIsEditingPassword(false);
    }

    const submit = (e) => {
        e.preventDefault();
        console.log(newPassword)
        updatePassword(user, newPassword).then(() => {
            alert("Password updated");
        }).catch((error) => {
            alert("Password failed");
        });
        updateProfile(auth.currentUser, {
            displayName: newDisplayName
        }).then(() => {
            alert("Profile updated");
        }).catch((error) => {
            alert("Profile failed");
        });
    }

    const cancel = () => {
        reset();
        setIsInTabs(true);
    }

    // Users

    const [isInUsers, setIsInUsers] = useState(false);

    const openUsers = () => {
        reset();
        setIsInUsers(true);
    }

    // Saved Files

    const [isInSavedFiles, setIsInSavedFiles] = useState(false);

    const openSavedFiles = () => {
        reset();
        setIsInSavedFiles(true);
    }

    // Shared Files

    const [isInSharedFiles, setIsInSharedFiles] = useState(false);

    const openSharedFiles = () => {
        reset();
        setIsInSharedFiles(true);
    }

    // Instructions

    const [isInInstructions, setIsInInstructions] = useState(false);

    const openInstructions = () => {
        reset();
        setIsInInstructions(true);
    }

    // User Feedback

    const [isInUserfeedback, setIsInUserfeedback] = useState(false);

    const openUserfeedback = () => {
        reset();
        setIsInUserfeedback(true);
    }

    // Support

    const [isInSupport, setIsInSupport] = useState(false);

    const openSupport = () => {
        reset();
        setIsInSupport(true);
    }

    //

    const reset = () => {
        setIsInAccountSettings(false);
        setIsInUsers(false);
        setIsInSavedFiles(false);
        setIsInTabs(false);
        setIsInSharedFiles(false);
        setIsInInstructions(false);
        setIsInUserfeedback(false);
        setIsInSupport(false);
    }

    const fetchAndDisplayDocuments = async () => {
        const collectionName = 'users';
        const documents = await listDocuments(collectionName);
        console.log(documents);
        setUlist(documents);
    }

    useEffect(() => {
        fetchAndDisplayDocuments();
    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent natural form submission
        console.log(feedback)
        try {
            const response = await fetch('http://localhost:8080/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ feedback: feedback })
            });

            if (response.ok) {
                const result = await response.text();
                console.log(result); // Or handle user feedback on success
                alert('Email sent successfully!');
            } else {
                throw new Error('Failed to send email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending email.');
        }
    };

    const handleInputChange = (event) => {
        setFeedback(event.target.value);
    };

    // support tab system

    const [activeTab, setActiveTab] = useState(1);

    // Function to change the active tab
    const selectTab = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Content for each tab, updated with specific names and content descriptions
    const tabs = [
        {
            id: 1, name: "Interacting with the Map",
            content: "<h2>Interacting with the Map</h2>Content for Interacting with the Map"
        },
        {
            id: 2, name: "Saving Files",
            content: "<h2>Saving Files</h2> Content for Saving Files"
        },
        {
            id: 3, name: "Sharing Files",
            content: "<h2>Sharing Files</h2>Content for Sharing Files"
        },
        {
            id: 4, name: "Your User Account",
            content: "<h2>Your User Account</h2>Content for Your User Account"
        },
        {
            id: 5, name: "Trouble Shooting",
            content: "<h2>Trouble Shooting</h2>Content for Trouble Shooting"
        },
        {
            id: 6, name: "Security & Privacy",
            content: "<h2>Security & Privacy</h2>Content for Security & Privacy"
        }
    ];

    return (
        <>
            <MobileView>
                <div className='settingsMobile'>
                    <div className="settingMobile_inner_leftMenu">
                        <button onClick={openAccountSettings}
                            className="setting_menu_button_mobile">
                            <IoKeyOutline className="leftMenuIcons" /> Reset Password</button>
                        {/* <button onClick={openUsers}
                            className="setting_menu_button_mobile">
                            <FaRegUser className="leftMenuIcons" /> Users</button> */}
                        <button onClick={openInstructions}
                            className="setting_menu_button_mobile">
                            <LuClipboardList className="leftMenuIcons" /> Instructions</button>
                        <button onClick={openSupport}
                            className="setting_menu_button_mobile">
                            <TbFolderQuestion className="leftMenuIcons" /> Support</button>
                    </div>
                </div>

                {isInAccountSettings ? (
                    <div className="AccountSettingsMobile">
                        <div className="AccountSettingInnerMobile">
                            <h2>Castle Howard Estate</h2>
                            <form className="AccountFormMobile">
                                <div className="AccountSettingsinputGroupMobile">
                                    <label htmlFor="username">Username:</label>
                                    {isEditingDisplayName ? (
                                        <div>
                                            <input type="text" id="username" name="username" onChange={(e) => setNewDisplayName(e.target.value)} placeholder="Enter your username" />
                                            <button className="AccountSettingsPasswordButtonDone" onClick={editDoneDisplayName} type="button">Done</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="placeholderMobile">{user.displayName}</div>
                                            <button className="AccountSettingsPasswordButtonChangeMobile" onClick={editDisplayName} type="button">Edit</button>
                                        </div>
                                    )}
                                </div>
                                <div className="AccountSettingsinputGroupMobile">
                                    <label htmlFor="password">Password:</label>
                                    {isEditingPassword ? (
                                        <div>
                                            <input type="password" id="password" name="password" onChange={(e) => setNewPassword(e.target.value)}
                                                placeholder="Enter your password" />
                                            <button className="AccountSettingsPasswordButtonDone" onClick={editDonePassword} type="button">Done</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="placeholderMobile">************</div>
                                            <button className="AccountSettingsPasswordButtonChangeMobile" onClick={editPassword} type="button">Edit</button>
                                        </div>
                                    )}
                                </div>
                                <div className="AccountSettingsinputGroupMobile">
                                    <label htmlFor="email">Email Address:</label>
                                    <div className="placeholderMobile">{user.email}</div>
                                </div>
                                <div className="AccountSettingsinputGroupMobile">
                                    <label htmlFor="location">Location:</label>
                                    <select className="AccountSettingsdropdownselect" id="location" name="location">
                                        <option value="">Select Location</option>
                                        <option value="location1">Location 1</option>
                                        <option value="location2">Location 2</option>
                                        <option value="location3">Location 3</option>
                                    </select>
                                </div>
                                <div className="AccountSettingsinputGroupMobile">
                                    <button className="AccountSettingsButtonCancel" onClick={cancel} type="button">Cancel</button>
                                    <button className="AccountSettingsButtonSaveMobile" onClick={submit} type="submit">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    null
                )}
            </MobileView>

            <BrowserView>
                <div className='settings'>
                    <div className="setting_inner_leftMenu">
                        <h2>Client Account</h2>
                        <div>
                            <button onClick={cancel}
                                className="setting_menu_buttonWhite">
                                <HiDevicePhoneMobile className="leftMenuIcons" /> Dashboard</button>
                        </div>
                        <div>
                            <button onClick={openAccountSettings}
                                className={`setting_menu_button ${isMapViewActive ? '' : 'active'}`}>
                                <IoKeyOutline className="leftMenuIcons" /> Reset Password</button>
                        </div>
                        {/* <div>
                            <button onClick={openUsers}
                                className="setting_menu_button">
                                <FaRegUser className="leftMenuIcons" /> Users</button>
                        </div> */}
                        {/* <div>
                            <button onClick={openSavedFiles}
                                className="setting_menu_button">
                                <TbDeviceFloppy className="leftMenuIcons" /> Saved Files</button>
                        </div> */}
                        {/* <div>
                            <button onClick={openSharedFiles}
                                className="setting_menu_button">
                                <PiFolderSimpleUser className="leftMenuIcons" /> Shared Files</button>
                        </div> */}
                        <div>
                            <button onClick={openInstructions}
                                className="setting_menu_button">
                                <LuClipboardList className="leftMenuIcons" /> Instructions</button>
                        </div>
                        <div>
                            <button onClick={openUserfeedback}
                                className="setting_menu_button">
                                <VscFeedback className="leftMenuIcons" /> User Feedback</button>
                        </div>
                        <div>
                            <button onClick={openSupport}
                                className="setting_menu_button">
                                <TbFolderQuestion className="leftMenuIcons" /> Support</button>
                        </div>
                    </div>
                    {isInTabs ? (
                        <div className='settings_inner'>
                            <h2>Castle Howard Estate</h2>
                            <div>
                                {/* <button onClick={handlesettingsClose}
                            className="setting_to_map_button">
                            Back to Map
                        </button> */}
                            </div>
                            {/* Component structure */}
                            <div className="settings__container_one">
                                <div className='settings__container__content__item__content'>
                                    <div class="settings_inner_container">
                                        <div class="square" onClick={openAccountSettings}>
                                            <p className="settings_inner_container_Header">
                                                <IoKeyOutline className="leftMenuIcons" />
                                                Reset Password
                                            </p>
                                        </div>
                                        {/* <div class="square" onClick={openUsers}>
                                            <p className="settings_inner_container_Header">
                                                <FaRegUser className="leftMenuIcons" />
                                                Users
                                            </p>
                                        </div> */}
                                        {/* <div class="square" onClick={openSavedFiles}>
                                            <p className="settings_inner_container_Header">
                                                <TbDeviceFloppy className="leftMenuIcons" />
                                                Saved Files
                                            </p>
                                        </div>
                                        <div class="square" onClick={openSharedFiles}>
                                            <p className="settings_inner_container_Header">
                                                <PiFolderSimpleUser className="leftMenuIcons" />
                                                Shared Files
                                            </p>
                                        </div> */}
                                        <div class="square" onClick={openInstructions}>
                                            <p className="settings_inner_container_Header">
                                                <LuClipboardList className="leftMenuIcons" />
                                                Instructions
                                            </p>
                                        </div>
                                        <div class="square" onClick={openUserfeedback}>
                                            <p className="settings_inner_container_Header">
                                                <VscFeedback className="leftMenuIcons" />
                                                User Feedback
                                            </p>
                                        </div>
                                        <div class="square" onClick={openSupport}>
                                            <p className="settings_inner_container_Header">
                                                <TbFolderQuestion className="leftMenuIcons" />
                                                Support
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings__container_two">
                                <div className='settings__container__content__item__content'>
                                    <div class="settings_inner_container">
                                        {/* <div class="square" onClick={openInstructions}>
                                            <p className="settings_inner_container_Header">
                                                <LuClipboardList className="leftMenuIcons" />
                                                Instructions
                                            </p>
                                        </div>
                                        <div class="square" onClick={openUserfeedback}>
                                            <p className="settings_inner_container_Header">
                                                <VscFeedback className="leftMenuIcons" />
                                                User Feedback
                                            </p>
                                        </div> */}
                                        {/* <div class="square" onClick={openSupport}>
                                            <p className="settings_inner_container_Header">
                                                <TbFolderQuestion className="leftMenuIcons" />
                                                Support
                                            </p>
                                        </div> */}
                                        <div class="squareSkylark">
                                            <p className="settings_inner_container_Header">Harrier Land App
                                            </p>
                                        </div>
                                        <div class="square" style={{opacity: '0', pointerEvents: 'none'}} onClick={openSupport}>
                                            <p className="settings_inner_container_Header">
                                                <TbFolderQuestion className="leftMenuIcons" />
                                                Support
                                            </p>
                                        </div>
                                        <div class="square" style={{opacity: '0', pointerEvents: 'none'}} onClick={openSupport}>
                                            <p className="settings_inner_container_Header">
                                                <TbFolderQuestion className="leftMenuIcons" />
                                                Support
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings__container_three">
                                <div className='settings__container__content__item__content'>
                                    <div className='settings__container__content__item__content'>
                                        <div class="settings_inner_container">
                                            <div class="banner_updates" style={{ backgroundPositionY: '-450px', backgroundSize: '100%', backgroundImage: `url(${banner})` }}>
                                                <p className="banner_updates_header">
                                                    Skylark for Authorities
                                                </p>
                                                <p className="banner_updates_subheader">
                                                    Subheading appears about banner.
                                                </p>
                                                <button className="banner_updates_button">
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings__container_four">
                                <div className='settings__container__content__item__content'>
                                    <div className='settings__container__content__item__content'>
                                        <div class="settings_inner_container">
                                            <div class="square_updates">
                                                <p className="settings_inner_container_Headersquare_updates">
                                                    Latest Update Notes
                                                </p>
                                            </div>
                                            <div class="square_updates">
                                                <p className="settings_inner_container_square_updates">
                                                    Lorem ipsum dolor sit amet consectetur
                                                    adipiscing elit sed do eiusmod tempor inci
                                                    didunt ut labore et dolore magna.
                                                </p>
                                            </div>
                                            <div class="square_updates">
                                                <p className="settings_inner_container_square_updates">
                                                    Lorem ipsum dolor sit amet consectetur
                                                    adipiscing elit sed do eiusmod tempor inci
                                                    didunt ut labore et dolore magna.
                                                </p>
                                            </div>
                                            <div class="square_updates">
                                                <p className="settings_inner_container_square_updates">Lorem ipsum dolor sit amet consectetur
                                                    adipiscing elit sed do eiusmod tempor inci
                                                    didunt ut labore et dolore magna.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings__container_five_left">
                                <p className="settings__container_five_left_header">
                                    Latest Update Notes
                                </p>
                                Lorem ipsum dolor sit amet consectetur
                                adipiscing elit sed do eiusmod tempor inci
                                didunt ut labore et dolore magna aliqua
                                ut enim ad minim veniam quis nostrud exer
                                citation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat. Duis aute irure dolo
                                rin reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur.
                                <p />
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    {/* {isInAccountSettings ? (
                        <>
                            <BrowserView>
                                <div className="AccountSettings">
                                    <div className="AccountSettingInner">
                                        <h2>Castle Howard Estate</h2>
                                        <form className="AccountForm">
                                            <div className="AccountSettingsinputGroup">
                                                <label htmlFor="username">Username:</label>
                                                {isEditingDisplayName ? (
                                                    <div>
                                                        <input type="text" id="username" name="username" onChange={(e) => setNewDisplayName(e.target.value)} placeholder="Enter your username" />
                                                        <button className="AccountSettingsPasswordButtonDone" onClick={editDoneDisplayName} type="button">Done</button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="placeholder">{user.displayName}</div>
                                                        <button className="AccountSettingsPasswordButtonChange" onClick={editDisplayName} type="button">Edit</button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="AccountSettingsinputGroup">
                                                <label htmlFor="password">Password:</label>
                                                {isEditingPassword ? (
                                                    <div>
                                                        <input type="password" id="password" name="password" onChange={(e) => setNewPassword(e.target.value)}
                                                            placeholder="Enter your password" />
                                                        <button className="AccountSettingsPasswordButtonDone" onClick={editDonePassword} type="button">Done</button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="placeholder">************</div>
                                                        <button className="AccountSettingsPasswordButtonChange" onClick={editPassword} type="button">Edit</button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="AccountSettingsinputGroup">
                                                <label htmlFor="email">Email Address:</label>
                                                <div className="placeholder">{user.email}</div>
                                            </div>
                                            <div className="AccountSettingsinputGroup">
                                                <label htmlFor="location">Location:</label>
                                                <select className="AccountSettingsdropdownselect" id="location" name="location">
                                                    <option value="">Select Location</option>
                                                    <option value="location1">Location 1</option>
                                                    <option value="location2">Location 2</option>
                                                    <option value="location3">Location 3</option>
                                                </select>
                                            </div>
                                            <div className="AccountSettingsbuttons">
                                                <button className="AccountSettingsButtonSave" onClick={submit} type="submit">Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </BrowserView>
                        </>
                    ) : (
                        null
                    )} */}

                    {/* {isInUsers ? (
                        <BrowserView>
                            <div className="AccountSettings">
                                <div className="AccountSettingInner">
                                    <h2>Castle Howard Estate</h2>
                                    <div className="AccountSettingsUser">
                                        {ulist.map((user) => (
                                            <div className="AccountSettingsUserInner">
                                                <div className="AccountSettingsUserDetails">
                                                    <div className="AccountSettingsUserDetailsInner">
                                                        <div className="AccountSettingsUserDetailsName">{user.data.name}</div>
                                                        <div className="AccountSettingsUserDetailsEmail">{user.data.username}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </BrowserView>
                    ) : (
                        null
                    )} */}

                    {isInSavedFiles ? (
                        <div className="AccountSettings">
                            <div className="AccountSettingInner">
                                <h2>Castle Howard Estate</h2>
                                Saved Files
                                <div className="AccountSettingsbuttons">
                                    <button className="AccountSettingsButtonCancel" onClick={cancel} type="button">Menu</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    {isInSharedFiles ? (
                        <div className="AccountSettings">
                            <div className="AccountSettingInner">
                                <h2>Castle Howard Estate</h2>
                                Shared Files
                                <div className="AccountSettingsbuttons">
                                    <button className="AccountSettingsButtonCancel" onClick={cancel} type="button">Menu</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    {isInInstructions ? (
                        <div className="AccountSettings">
                            <div className="AccountSettingInner">
                                <h2>Castle Howard Estate</h2>
                                Instructions
                                <div className="AccountSettingsbuttons">
                                    {/* <button className="AccountSettingsButtonCancel" onClick={cancel} type="button">Menu</button> */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    {isInUserfeedback ? (
                        <BrowserView>
                            <div className="AccountSettings">
                                <div className="AccountSettingInner">
                                    <h2>Castle Howard Estate</h2>
                                    Let us know your thoughts
                                    <form className="feedbackForm" onSubmit={handleSubmit}>
                                        <textarea
                                            cols="20"
                                            rows="10" // Modified rows from 100 to 10 for practicality
                                            className="feedbackFormInput"
                                            placeholder="Add your comments"
                                            name="feedback"
                                            value={feedback}
                                            onChange={handleInputChange}
                                        ></textarea>
                                        <button className="AccountSettingsButtonSave" type="submit">Send</button>
                                    </form>
                                </div>
                            </div>
                        </BrowserView>
                    ) : (
                        null
                    )}

                    {isInSupport ? (
                        <div className="AccountSettings">
                            <div className="AccountSettingInner">
                                <h2>Castle Howard Estate</h2>
                                <div>Support</div>
                                <div className="AccountSettingsbuttons">
                                    {/* <button className="AccountSettingsButtonCancel" onClick={cancel} type="button">Menu</button> */}
                                </div>
                                <div className="Tabs">
                                    <div className="TabList">
                                        {tabs.map(tab => (
                                            <div
                                                key={tab.id}
                                                className={`TabItem ${activeTab === tab.id ? 'active' : ''}`}
                                                onClick={() => selectTab(tab.id)}
                                            >
                                                {tab.name}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="TabContent">
                                        <div dangerouslySetInnerHTML={{ __html: tabs.find(tab => tab.id === activeTab)?.content }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null
                    )}

                </div>
            </BrowserView>
        </>
    );
}


export default SettingsPanel;
