import React from 'react';

const SaveOptions = ({
  openFilenameInputModal,
  saveCurrentDataSetClick,
  loadMenuClick,
  loggedInToAPI
}) => {
  return (
    <div>
      {loggedInToAPI ? (
        <>
          <div className='save_as' onClick={openFilenameInputModal}>
            <div className='save_text'>Save As</div>
          </div>
          <div className='save' onClick={saveCurrentDataSetClick}>
            <div className='save_text'>Save</div>
          </div>
          <div className='load' onClick={loadMenuClick}>
            <div className='load_text'>Load</div>
          </div>
        </>
      ) : (
        <>
          <div className='save_as_blocked' onClick={openFilenameInputModal}>
            <div className='save_text'>Save As</div>
          </div>
          <div className='save_blocked' onClick={saveCurrentDataSetClick}>
            <div className='save_text'>Save</div>
          </div>
          <div className='load_blocked' onClick={loadMenuClick}>
            <div className='load_text'>Load</div>
          </div>
        </>
      )}
    </div>
  );
};

export default SaveOptions;
