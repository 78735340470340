import { useRef } from 'react';
import * as THREE from 'three';
import { useState } from 'react';

export let map;

export let landtype_metric_calc = [];

// Scaling factor for shapes
export let shape_scaling_factor = 0.57;

// Speed of animation
export let animate_speed = 300;

// Loading timer count
export let loading_timer_count = 0;

// Object for storing color mappings
export let colorMap = {};

// Three.js object for storing individual shape meshes
export let fmesh = new THREE.Object3D();

// Three.js object for storing all shape meshes
export let fullmesh = new THREE.Object3D();

// Variable for storing the currently found feature
export let foundFeature;
export const setFoundFeature = (newFeature) => {
  foundFeature = newFeature;
};

// Variable for storing the currently found feature initial depth
export let foundFeatureInitDepths = {};
export const setFoundFeatureInitDepth = (skylark_id, newDepth) => {
  foundFeatureInitDepths[skylark_id] = newDepth;
};

// Variable for storing the currently found feature new depth
export let foundFeatureNewDepth = null;
export const setFoundFeatureNewDepth = (newDepth) => {
  foundFeatureNewDepth = newDepth;
};

export function useGlobalRefs() {
  // Refs
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const centroidRef = useRef(null);
  const clickedInfoRef = useRef(null);
  const dropdownListRef = useRef(null);
  const polygonCustomLayerRef = useRef(null);
  const longstandingGeoJSONRef = useRef(null);
  const originalGeoJSONforComparingRef = useRef(null);
  const mapContainerRef = useRef(null);
  const loadedDataGeojson = useRef(null);
  const mapboxTokenRef = useRef('pk.eyJ1IjoiamV2b25tYWhvbmV5IiwiYSI6ImNrbjRpOThhbzBsOTkycm5xYjVodGlhZnoifQ.nUA78X2hM4qVWn-xD8l3lw');
  const piechartRef = useRef(null);  // Added to keep reference to the chart instance

  return {
    // Refs
    mapRef,
    drawRef,
    centroidRef,
    clickedInfoRef,
    dropdownListRef,
    polygonCustomLayerRef,
    longstandingGeoJSONRef,
    mapContainerRef,
    loadedDataGeojson,
    originalGeoJSONforComparingRef,
    mapboxTokenRef,
    piechartRef
  };
}

export function useGlobalFileStates() {
  // States
  const [isFilenameInputOpen, setIsFilenameInputOpen] = useState(false);
  const [newFilename, setNewFilename] = useState('');
  const [newFileScreenShot, setNewFileScreenShot] = useState('');
  const [loadedFileName, setloadedFileName] = useState('Base Data');

  return {
    // States
    isFilenameInputOpen,
    setIsFilenameInputOpen,
    newFilename,
    setNewFilename,
    newFileScreenShot,
    setNewFileScreenShot,
    loadedFileName,
    setloadedFileName,
  };
}

export function useGlobalState() {
  // States
  const [clickedInfo, setClickedInfo] = useState(null);
  const [clickedInfoHeader, setClickedInfoHeader] = useState(null);
  const [clickedInfoHeaderMobile, setClickedInfoHeaderMobile] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDrawing, setisDrawing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filenames, setFilenames] = useState([]);
  const [isMenuLoadClick, setisMenuLoadClick] = useState(false);
  const [isHasSaved, setisHasSaved] = useState(false);
  const [isHasSavedCurrentData, setisHasSavedCurrentData] = useState(false);
  const [savedFilename, setsavedFilename] = useState(false);
  const [openAuth, setopenAuth] = useState(false);
  const [isSelectingLandtypes, setisSelectingLandtypes] = useState(false);
  const [isLeftDrawerOpen, setisLeftDrawerOpen] = useState(true);
  const [isLeftSubDrawerOpen, setisLeftSubDrawerOpen] = useState(false);
  const [selectedLandTypes, setSelectedLandTypes] = useState([]);
  const [PiechatSelectionCancelOnClick, setPiechatSelectionCancelOnClick] = useState(false);
  const [isViewingPieChart, setisViewingPieChart] = useState(false);
  const [isViewingForecast, setisViewingForecast] = useState(false);
  const [isModifying, setisModifying] = useState(false);
  const [isComparing, setisComparing] = useState(false);
  const [isBenchmarking, setisBenchmarking] = useState(false);
  const [benchmarkingData, setBenchmarkingData] = useState([]);
  const [benchmarkingVisible, setBenchmarkingVisible] = useState(false);
  const [isInSettings, setIsInSettings] = useState(false);
  const [isInAboutPanel, setIsInAboutPanel] = useState(false);
  const [isInPrivacyPanel, setIsInPrivacyPanel] = useState(false);
  const [isInTacPanel, setIsInTacPanel] = useState(false);
  const [isInInvestorsPanel, setIsInInvestorsPanel] = useState(false);
  const [dropdownhighlightedIndex, setdropdownhighlightedIndex] = useState(null);
  const [is2DViewActive, setIs2DViewActive] = useState(false);
  const [isUKHabColoursActive, setIsUKHabColoursActive] = useState(false);
  const [uniqueTextureNamesArray, setUniqueTextureNamesArray] = useState([]);
  const [storedfeaturecurrentlandtype, setStoredFeatureCurrentLandtype] = useState('');
  const [mapbearing, setMapBearing] = useState(0);
  const [isLanduse, setIsLanduse] = useState(false);
  const [isViewingLargePieChart, setisViewingLargePieChart] = useState(false);
  const [comparingLandtypeSelectedOption, setcomparingLandtypeSelectedOption] = useState(''); // Added to store the selected option in the comparing landtype dropdown
  const [isDraggableSubDrawerOpen, setisDraggableSubDrawerOpen] = useState(false);
  const [selectedYearEditing, setSelectedYearEditing] = useState('2024-01-01T00:00:00+00:00');
  const [isCtrlDown, setIsCtrlDown] = useState(false);
  const [loadedAmount, setLoadedAmount] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isControlsNavOpen, setIsControlsNavOpen] = useState(false);
  const [mapSavedWithFilenameSuccess, setMapSavedWithFilenameSuccess] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState('ParcelCarbon');

  const [hasCreatedNewPolygonInModify, setHasCreatedNewPolygonInModify] = useState('');

  const [loadedPercentage, setLoadedPercentage] = useState(0);

  const [temporaryGeoJSONIntendedForSaving, setTemporaryGeoJSONIntendedForSaving] = useState([]);

  const [currentMapId, setCurrentMapId] = useState(null);

  const [loggedInToAPI, setLoggedInToAPI] = useState(false);

  const [acknowledgeNewFaetureNewDepth , setAcknowledgeNewFaetureNewDepth] = useState(false);

  return {
    // States
    clickedInfo,
    setClickedInfo,
    isVisible,
    setIsVisible,
    isDrawing,
    setisDrawing,
    isLoading,
    setIsLoading,
    filenames,
    setFilenames,
    isMenuLoadClick,
    setisMenuLoadClick,
    isHasSaved,
    setisHasSaved,
    isHasSavedCurrentData,
    setisHasSavedCurrentData,
    savedFilename,
    setsavedFilename,
    openAuth,
    setopenAuth,
    isSelectingLandtypes,
    setisSelectingLandtypes,
    isLeftDrawerOpen,
    setisLeftDrawerOpen,
    isLeftSubDrawerOpen,
    setisLeftSubDrawerOpen,
    selectedLandTypes,
    setSelectedLandTypes,
    selectedMetric,
    setSelectedMetric,
    PiechatSelectionCancelOnClick,
    setPiechatSelectionCancelOnClick,
    isViewingPieChart,
    setisViewingPieChart,
    isViewingForecast,
    setisViewingForecast,
    selectedYearEditing,
    setSelectedYearEditing,
    isModifying,
    setisModifying,
    isComparing,
    setisComparing,
    hasCreatedNewPolygonInModify,
    setHasCreatedNewPolygonInModify,
    isBenchmarking,
    setisBenchmarking,
    benchmarkingData,
    setBenchmarkingData,
    benchmarkingVisible,
    setBenchmarkingVisible,
    isInSettings,
    setIsInSettings,
    isInAboutPanel,
    setIsInAboutPanel,
    isInPrivacyPanel,
    setIsInPrivacyPanel,
    isInTacPanel,
    setIsInTacPanel,
    isInInvestorsPanel,
    setIsInInvestorsPanel,
    dropdownhighlightedIndex,
    setdropdownhighlightedIndex,
    is2DViewActive,
    setIs2DViewActive,
    isUKHabColoursActive,
    setIsUKHabColoursActive,
    uniqueTextureNamesArray,
    setUniqueTextureNamesArray,
    storedfeaturecurrentlandtype,
    setStoredFeatureCurrentLandtype,
    mapbearing,
    setMapBearing,
    isLanduse,
    setIsLanduse,
    isViewingLargePieChart,
    setisViewingLargePieChart,
    comparingLandtypeSelectedOption,
    setcomparingLandtypeSelectedOption,
    isDraggableSubDrawerOpen,
    setisDraggableSubDrawerOpen,
    clickedInfoHeader,
    setClickedInfoHeader,
    clickedInfoHeaderMobile,
    setClickedInfoHeaderMobile,
    loadedPercentage,
    setLoadedPercentage,
    isCtrlDown,
    setIsCtrlDown,
    loadedAmount,
    setLoadedAmount,
    mapLoaded,
    setMapLoaded,
    temporaryGeoJSONIntendedForSaving,
    setTemporaryGeoJSONIntendedForSaving,
    currentMapId,
    setCurrentMapId,
    loggedInToAPI,
    setLoggedInToAPI,
    isControlsNavOpen,
    setIsControlsNavOpen,
    acknowledgeNewFaetureNewDepth,
    setAcknowledgeNewFaetureNewDepth,
    mapSavedWithFilenameSuccess,
    setMapSavedWithFilenameSuccess
  };
}