import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { IoClose } from "react-icons/io5";

const ControlsNav = ({
    globalRefs,
    globalState,
    setIsUKHabColoursActive,
    selectedYearEditing,
    closeControlsNav,
    isControlsNavOpen,
    setIsControlsNavOpen
}) => {
    const [is2DViewActive, setIs2DViewActive] = useState(false);
    const [isMapViewActive, setIsMapViewActive] = useState(false);
    const [isOrbitActive, setIsOrbitActive] = useState(false);
    const [isPanActive, setIsPanActive] = useState(false);
    const [isDollyActive, setIsDollyActive] = useState(false);
    const [mapHeading, setMapHeading] = useState(0); // Initial heading state
    const [mapPitch, setMapPitch] = useState(0); // Initial pitch state

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    const mapRef = globalRefs.mapRef;

    // Function to handle map zoom
    const handleZoom = (zoomIn) => {
        if (mapRef.current) {
            const zoom = mapRef.current.getZoom();
            mapRef.current.setZoom(zoomIn ? zoom + .1 : zoom - .1);
        }
    };

    // Function to handle map heading change
    const handleHeadingChange = (event) => {
        const newHeading = event.target.value;
        setMapHeading(newHeading);
        if (mapRef.current) {
            mapRef.current.setBearing(newHeading);
        }
    };

    // Function to handle map pitch change
    const handlePitchChange = (event) => {
        const newPitch = event.target.value;
        setMapPitch(newPitch);
        if (mapRef.current) {
            mapRef.current.setPitch(newPitch);
        }
    };

    // Sync sliders with map state
    useEffect(() => {
        const onMapMove = () => {
            setMapHeading(mapRef.current.getBearing());
            setMapPitch(mapRef.current.getPitch());
        };

        setTimeout(function () {
            mapRef.current.on('rotate', onMapMove);
            mapRef.current.on('pitch', onMapMove);
        }, 1000)
    },);

    return (
        <>
            {isControlsNavOpen ? (
                <div className="controls_nav">
                    <span className="closeNav" onClick={closeControlsNav}>
                        <IoClose />
                    </span>
                    <div className="controls_sliders">
                        <div className="slider-container">
                            <label>Bearing</label>
                            <input
                                type="range"
                                min="-180"
                                max="180"
                                value={mapHeading}
                                onChange={handleHeadingChange}
                            />
                        </div>
                        <div className="slider-container">
                            <label>Pitch</label>
                            <input
                                type="range"
                                min="0"
                                max="80"
                                value={mapPitch}
                                onChange={handlePitchChange}
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="zoomOutBtn" onClick={() => handleZoom(false)}>-</button>
                        <button className="zoomInBtn" onClick={() => handleZoom(true)}>+</button>
                    </div>
                </div>
            ) : (
                <div className="controlsOpenButton">
                    <button onClick={() => setIsControlsNavOpen(true)}>Controls</button>
                </div>
            )
            }
        </>
    );
};

export default ControlsNav;
