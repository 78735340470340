export const results_tranlsated =
{
  "cropland": {
    "BD_Parcel": 5.471,
    "ParcelCarbon": 137.814,
    "ParcelCarbonFlux": 0.793
  },
  "arable and horticulture": {
    "BD_Parcel": 5.471,
    "ParcelCarbon": 137.814,
    "ParcelCarbonFlux": 0.793
  },
  "arable field margins": {
    "BD_Parcel": 8.998,
    "ParcelCarbon": 109.018,
    "ParcelCarbonFlux": -0.719
  },
  "temporary grasslands and clover leys": {
    "BD_Parcel": 8.998,
    "ParcelCarbon": 109.018,
    "ParcelCarbonFlux": -0.719
  },
  "cereal crops": {
    "BD_Parcel": 5.471,
    "ParcelCarbon": 137.814,
    "ParcelCarbonFlux": 0.793
  },
  "non-cereal crops": {
    "BD_Parcel": 5.471,
    "ParcelCarbon": 137.814,
    "ParcelCarbonFlux": 0.793
  },
  "intensive orchards": {
    "BD_Parcel": 8.998,
    "ParcelCarbon": 109.018,
    "ParcelCarbonFlux": -0.719
  },
  "horticulture": {
    "BD_Parcel": 8.998,
    "ParcelCarbon": 109.018,
    "ParcelCarbonFlux": -0.719
  },
  "urban": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "built-up areas and gardens": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "open mosaic undeveloped land": {
    "BD_Parcel": 0,
    "ParcelCarbon": 0,
    "ParcelCarbonFlux": 0
  },
  "developed, bare sealed surface": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "buildings": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "other developed land": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "artificial unvegetated sealed surface": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "suburban natural surface": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "build linear features": {
    "BD_Parcel": 0,
    "ParcelCarbon": 41.124,
    "ParcelCarbonFlux": 0
  },
  "grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "acid grassland": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "lowland dry acid grassland": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "upland acid grassland": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "montane acid grasslands": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "other upland acid grassland": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "bracken": {
    "BD_Parcel": 3.228,
    "ParcelCarbon": 158.182,
    "ParcelCarbonFlux": 0.085
  },
  "calcareous grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "lowland calcareous grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "upland calcareous grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "neutral grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "lowland meadows": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "upland hay meadows": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "arrhenatherum neutral grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "lolium-cynodon neutral grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "deschampsia neutral grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "holcus-juncus neutral grassland": {
    "BD_Parcel": 14.326,
    "ParcelCarbon": 106.877,
    "ParcelCarbonFlux": 0
  },
  "modified grassland": {
    "BD_Parcel": 8.998,
    "ParcelCarbon": 109.018,
    "ParcelCarbonFlux": -0.719
  },
  "heathland and shrub": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "dwarf shrub heath": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "lowland heath": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "dry heaths, lowland": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "wet heaths, lowland": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "upland heath": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "dry heaths, upland": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "wet heaths, upland": {
    "BD_Parcel": 35.038,
    "ParcelCarbon": 265.347,
    "ParcelCarbonFlux": 0.143
  },
  "hedgerow": {
    "BD_Parcel": 0,
    "ParcelCarbon": 0,
    "ParcelCarbonFlux": 0
  },
  "hedgerow, priority habitat": {
    "BD_Parcel": 0,
    "ParcelCarbon": 0,
    "ParcelCarbonFlux": 0
  },
  "other hedgerows": {
    "BD_Parcel": 0,
    "ParcelCarbon": 0,
    "ParcelCarbonFlux": 0
  },
  "dense shrub": {
    "BD_Parcel": 19.529,
    "ParcelCarbon": 136.927,
    "ParcelCarbonFlux": 0.085
  },
  "wetland": {
    "BD_Parcel": 25.455,
    "ParcelCarbon": 3089.903,
    "ParcelCarbonFlux": -1.458
  },
  "bog": {
    "BD_Parcel": 54.794,
    "ParcelCarbon": 1671.538,
    "ParcelCarbonFlux": -0.064
  },
  "blanket bog": {
    "BD_Parcel": 54.794,
    "ParcelCarbon": 1671.538,
    "ParcelCarbonFlux": -0.064
  },
  "lowland raised bog": {
    "BD_Parcel": 54.794,
    "ParcelCarbon": 1671.538,
    "ParcelCarbonFlux": -0.064
  },
  "fen, march, and swamp": {
    "BD_Parcel": 25.455,
    "ParcelCarbon": 3089.903,
    "ParcelCarbonFlux": -1.458
  },
  "lowland fens": {
    "BD_Parcel": 25.455,
    "ParcelCarbon": 3089.903,
    "ParcelCarbonFlux": -1.458
  },
  "aquatic minimal vegetation": {
    "BD_Parcel": 25.455,
    "ParcelCarbon": 3089.903,
    "ParcelCarbonFlux": -1.458
  },
  "reedbeds": {
    "BD_Parcel": 25.152,
    "ParcelCarbon": 109.917,
    "ParcelCarbonFlux": -9.669
  },
  "sparsely vegetated land": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "inland rock": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "inland rock...scree habitats": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "other inland rock scree": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "supralittoral rock": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "maritime cliff and slopes": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "supralittoral sediment": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "coastal sand dunes": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "coastal vegetated shingle": {
    "BD_Parcel": 17.711,
    "ParcelCarbon": 20.638,
    "ParcelCarbonFlux": -3.124
  },
  "rivers and lakes": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "standing water": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "eutrophic standing waters": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "canals": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "rivers and streams": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "marine transitional waters": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "littoral rock": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "littoral sediment": {
    "BD_Parcel": 12.117,
    "ParcelCarbon": 25.008,
    "ParcelCarbonFlux": -4.360
  },
  "coastal saltmarsh": {
    "BD_Parcel": 25.152,
    "ParcelCarbon": 109.917,
    "ParcelCarbonFlux": -9.669
  },
  "intertidal mudflats": {
    "BD_Parcel": 25.152,
    "ParcelCarbon": 109.917,
    "ParcelCarbonFlux": -9.669
  },
  "woodland": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "broadleave…yew woodland": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "upland oakwood": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "upland mixed ashwoods": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "lowland be…chwood": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "wet woodland": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "upland birchwoods": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "lowland mixed deciduous woodland": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "other woodland mixed": {
    "BD_Parcel": 16.055,
    "ParcelCarbon": 303.616,
    "ParcelCarbonFlux": -19.309
  },
  "coniferous woodland": {
    "BD_Parcel": 7.243,
    "ParcelCarbon": 254.498,
    "ParcelCarbonFlux": -24.289
  },
  "native pine woodland": {
    "BD_Parcel": 7.243,
    "ParcelCarbon": 254.498,
    "ParcelCarbonFlux": -24.289
  },
  "other scots pine woodland": {
    "BD_Parcel": 7.243,
    "ParcelCarbon": 254.498,
    "ParcelCarbonFlux": -24.289
  },
  "other coniferous woodland": {
    "BD_Parcel": 7.243,
    "ParcelCarbon": 254.498,
    "ParcelCarbonFlux": -24.289
  },
  "line of trees": {
    "BD_Parcel": 7.243,
    "ParcelCarbon": 254.498,
    "ParcelCarbonFlux": -24.289
  }
}