import React, { useState, useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import { isMobile } from 'react-device-detect';

const SearchBar = ({ onSearch, mapboxTokenRef, globalRefs, globalState }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;

  // Function to fetch geocoding data
  const fetchGeocodeData = async (query) => {
    const accessToken = mapboxTokenRef.current;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${accessToken}&limit=5`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.features; // We're interested in the features array
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
    }
    return [];
  };

  useEffect(() => {
    if (searchTerm.length > 2) { // To avoid too many requests
      const delayDebounceFn = setTimeout(async () => {
        const results = await fetchGeocodeData(searchTerm);
        setSuggestions(results);
      }, 300); // Debounce to limit requests while typing

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // onSearch(searchTerm);
  };

  const handleSuggestionClick = async (place_name) => {
    try {
      const results = await fetchGeocodeData(place_name);
      if (results.length > 0) {
        console.log('Suggestion clicked:', results[0])
        // const { center } = results[0].geometry;
        const [longitude, latitude] = results[0].center;
        console.log('Latitude:', latitude, 'Longitude:', longitude)
        polygonCustomLayerRef.current.cameraFlyToLatLong(latitude, longitude);
        // const [longitude, latitude] = center;
        // // Return the latitude and longitude
        // onSearch({ latitude, longitude });
      }
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
    } finally {
      setSuggestions([]);
    }
  };

  const mobileStatus = isMobile ? "mobile" : "not-mobile";

  return (
    <div>

      <form onSubmit={handleSubmit}>
        <input
          className={`searchBar ${mobileStatus}`}
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
          autoFocus={false}
        />
        {/* <button className="search_button" type="submit">
      <CiSearch />
    </button> */}
      </form>

      {suggestions.length > 0 && (
        <ul className={`suggestions ${mobileStatus}`}>
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onClick={() => handleSuggestionClick(suggestion.place_name)}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
