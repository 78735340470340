import React from 'react';
import { FaClock } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";

const Modify = ({
    ModifySelectionCancelOnClick,
    isViewingForecast,
    handleModifyClick,
    isModifying
}) => {

    return (
        <div className={`land_types ${isModifying ? 'selected' : ''}`} onClick={handleModifyClick}>
            <button>
                <HiPencil className="leftMenuIcons" /> Boundaries
            </button>
        </div>
    )
};

export default Modify;

