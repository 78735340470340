import React from 'react';
import { HiClipboardList } from "react-icons/hi";

const LandTypesButtonMobile = ({
  LandtypeSelectionCancelOnClick,
  isSelectingLandtypes
}) => {

  return (
    <div onClick={LandtypeSelectionCancelOnClick}>
      <button id='begin_draw_button' className='MobileIconButton'> 
        <HiClipboardList className="leftMenuIconsMobile" />
        <p>Land Key</p>
      </button>
    </div>
  )
};

export default LandTypesButtonMobile;
