import React from 'react';
import { IoMdEye } from "react-icons/io";

const BenchmarkButtonMobile = ({
    isBenchmarking,
    handleBenchmarkClick
}) => {
    return (
        <div onClick={handleBenchmarkClick}>
            <button className='MobileIconButton'>
                <IoMdEye className="leftMenuIconsMobile" />
                <p>Benchmark</p>
            </button>
        </div>
    );
};

export default BenchmarkButtonMobile;
