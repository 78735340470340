import React, { useEffect, useState } from 'react';
import { presetColors } from '../../../libs/colours.js';
import { landCoverClasses } from '../../../libs/ukhablandtypes.js';

import {
  foundFeature,
} from '../../core/globalVariables.js';

const List = ({
  isVisible,
  polygonCustomLayerRef,
  selectedYearEditing,
  dropdownhighlightedIndex,
  setdropdownhighlightedIndex,
  uniqueTextureNamesArray,
  isUKHabColoursActive,
  isLandUse,
  setIsLanduse,
  resetLeftSubMenu,
  setisLeftSubDrawerOpen,
  storedfeaturecurrentlandtype,
  setStoredFeatureCurrentLandtype,
  temporaryGeoJSONIntendedForSaving,
  setTemporaryGeoJSONIntendedForSaving
}) => {

  // Function to handle click on an item
  const handleItemClick = (index, landtype) => {
    polygonCustomLayerRef.current.handleCloseClickedInfo(landtype, selectedYearEditing);
    if (isUKHabColoursActive) {
      console.log("ukhab")
      polygonCustomLayerRef.current.updateColorToUKHAB(foundFeature, landtype);
    } else {
      console.log("regular")
      polygonCustomLayerRef.current.updateColor(foundFeature, landtype);
    }
    // Highlight the clicked item, if it's not already highlighted
    if (dropdownhighlightedIndex !== index) {
      setdropdownhighlightedIndex(index);
    } else {
      // If the clicked item is already highlighted, deselect it
      setdropdownhighlightedIndex(null);
    }
  };

  const handleCloseClickedInfoCancel = () => {
    polygonCustomLayerRef.current.handleCloseClickedInfo(storedfeaturecurrentlandtype, selectedYearEditing);
    console.log(storedfeaturecurrentlandtype)
    if (isUKHabColoursActive) {
      console.log("ukhab")
      polygonCustomLayerRef.current.updateColorToUKHAB(foundFeature, storedfeaturecurrentlandtype);
    } else {
      console.log("regular")
      polygonCustomLayerRef.current.updateColor(foundFeature, storedfeaturecurrentlandtype);
    }
    resetLeftSubMenu();
    setisLeftSubDrawerOpen(false);
  }

  const handleCloseClickedInfoButton = () => {
    polygonCustomLayerRef.current.handleCloseClickedInfoButton();
    resetLeftSubMenu();
    setisLeftSubDrawerOpen(false);
  }

  return (
    isLandUse && (
      isVisible && (
        <div className='dropdown_list_outer'>
          <div className='dropdown_list'>
            <div className='list'>
              {uniqueTextureNamesArray.map((landtype, index) => {
                const imageSource = `${process.env.PUBLIC_URL}/ukhab/${landtype}.png`;
                return (
                  <div
                    key={index}
                    onClick={() => handleItemClick(index, landtype)}
                    className={`itemblock ${dropdownhighlightedIndex === index ? 'highlighted' : ''}`}
                  >
                    {isUKHabColoursActive ? (
                      <span className="ukhabcolorcircle" >
                        <img className="ukhabcolorcircledropdown" src={imageSource} alt={landtype} />
                      </span>
                    ) : (
                      <span className='color_circle_landusechange' style={{ backgroundColor: presetColors[landtype] }}></span>
                    )}
                    <div className='list_item'>
                      {landtype.length > 30 ? `${landtype.substring(0, 30)}...` : landtype}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='clicked_info__close' onClick={() => handleCloseClickedInfoButton()}>Confirm</div>
          <div className='clicked_info__close_cancel' onClick={() => handleCloseClickedInfoCancel()}>Cancel</div>
        </div>
      )
    )
  );
}

export default List;
