import React from 'react';
import { FaClock } from "react-icons/fa";

const ForecastButtonMobile = ({
    ForecastCancelOnClick,
    isViewingForecast
}) => {

    return (
        <div onClick={ForecastCancelOnClick}>
            <button className='MobileIconButton'>
                <FaClock className="leftMenuIconsMobile" />
                <p>Forecast</p>
            </button>
        </div>
    )
};

export default ForecastButtonMobile;