import * as THREE from '../../dist/three.module.js';
import mapboxgl from 'mapbox-gl';
import * as TWEEN from '@tweenjs/tween.js';
import * as turf from '@turf/turf';
import { presetColors } from '../../libs/colours.js';
import { landCoverClasses } from '../../libs/ukhablandtypes';

import {
  shape_scaling_factor,
  fmesh,
  fullmesh,
  foundFeature,
  setFoundFeature,
  foundFeatureInitDepths,
  setFoundFeatureInitDepth,
  foundFeatureNewDepth,
  setFoundFeatureNewDepth,
  animate_speed,
  colorMap,
} from './globalVariables.js';

let setIsLoading;
let setIsVisible;
let setClickedInfo;
let setClickedInfoHeader;
let setClickedInfoHeaderMobile;
let longstandingGeoJSONRef;
let selectedMetricValue;
let selectedYearEditing;
let setBenchmarkingData;
let setBenchmarkingVisible;
let setdropdownhighlightedIndex;
let setIs2DViewActive;
let isUKHabColoursActive;
let setStoredFeatureCurrentLandtype;
let seenLandtypes;
let hVIPSP;
let isCtrlDown;
let setTemporaryGeoJSONIntendedForSaving;
let selectedMetric;
let landtype_metric_calc;
let newFeatureDepth;
let setAcknowledgeNewFaetureNewDepth;

let pspArray;

let dateKey, selectedFeaturesMetric;

let initPsp = 0;

const metrics = [
  'co2',
  'co2trains',
  'co2fuelstations',
  'co2buildings',
  'co2airports',
  'co2farms',
  'co2refineries',
];

let biodiversityTable = [];

let carbonfluxTable = [];

let carbonDensityTable = [];

let valueTable = [];

class SkylarkPolygonLayer {

  type = 'custom';
  renderingMode = '3d';

  // constructor

  constructor(id, latitude, longitude, json, globalRefs, globalState) {
    this.id = id;
    this.center = { latitude, longitude };
    this.passedjson = json;
    this.modelTransform = this.calculateModelTransform();
    this.map = globalRefs.mapRef;
    setIsLoading = globalState.setIsLoading;
    setIsVisible = globalState.setIsVisible;
    setClickedInfo = globalState.setClickedInfo;
    setClickedInfoHeader = globalState.setClickedInfoHeader;
    setClickedInfoHeaderMobile = globalState.setClickedInfoHeaderMobile;
    longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    selectedMetricValue = null;
    selectedMetric = globalState.selectedMetric;
    selectedYearEditing = globalState.selectedYearEditing;
    setBenchmarkingData = globalState.setBenchmarkingData;
    setBenchmarkingVisible = globalState.setBenchmarkingVisible;
    setdropdownhighlightedIndex = globalState.setdropdownhighlightedIndex;
    setIs2DViewActive = globalState.setIs2DViewActive;
    isUKHabColoursActive = globalState.isUKHabColoursActive;
    setStoredFeatureCurrentLandtype = globalState.setStoredFeatureCurrentLandtype;
    seenLandtypes = {};
    hVIPSP = 0;
    pspArray = {};
    isCtrlDown = false;
    dateKey = '2024-01-01T00:00:00+00:00';
    setTemporaryGeoJSONIntendedForSaving = globalState.setTemporaryGeoJSONIntendedForSaving;
    setAcknowledgeNewFaetureNewDepth = globalState.setAcknowledgeNewFaetureNewDepth;
  }

  // initial setup functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  async onAdd(map, gl) {
    this.scene = this.makeScene();
    this.checkDifferential(this.passedjson, selectedYearEditing);
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
      logarithmicDepthBuffer: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.autoClear = false;
    this.map = map;
    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = -1;
    this.raycaster.far = 1e10;
  }

  // determine and establish initial logged into API status - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  
  setLoggedintoAPI = () => {
    console.log('logged in');
    this.loggedInToAPI = true;
    console.log(this.loggedInToAPI);
  }

  // scene creation relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  makeScene() {
    const scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera();
    this.camera.far = 1000000000;
    const skyColor = 0xb1e1ff;
    const groundColor = 0xb97a20;
    this.templight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(new THREE.AmbientLight(0xffffff, .5));
    scene.add(new THREE.HemisphereLight(skyColor, groundColor, 0.25));
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.4);
    this.directionalLight.position.set(-70, -70, 100).normalize();
    scene.add(this.directionalLight);
    return scene;
  }

  // polygon map relation functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  calculateModelTransform() {
    const modelOrigin = [this.center.longitude, this.center.latitude];
    const modelAltitude = 2;
    const modelRotate = [Math.PI / 2, 0, 0];
    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );
    return {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };
  }

  get_biodiversity_table() {
    let habitatTypeMap = new Map();
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = longstandingGeoJSONRef.current.features[i];
      let habitatType = feature.feature_data_simple.HabitatType.toLowerCase();
      if (!habitatTypeMap.has(habitatType)) {
        habitatTypeMap.set(habitatType, feature);
      }
    }
    habitatTypeMap.forEach((feature, habitatType) => {
      if (feature.feature_data_simple_timeseries.BDUnits_perHA !== undefined) {
        biodiversityTable.push({
          habitatType: habitatType,
          property: feature.feature_data_simple_timeseries.BDUnits_perHA[dateKey]
        })
      }
    });
  }

  get_carbonflux_table() {
    let habitatTypeMap = new Map();
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = longstandingGeoJSONRef.current.features[i];
      let habitatType = feature.feature_data_simple.HabitatType.toLowerCase();
      if (!habitatTypeMap.has(habitatType)) {
        habitatTypeMap.set(habitatType, feature);
      }
    }
    habitatTypeMap.forEach((feature, habitatType) => {
      if (feature.feature_data_simple_timeseries.C_flux_tCO2e !== undefined) {
        carbonfluxTable.push({
          habitatType: habitatType,
          property: feature.feature_data_simple_timeseries.C_flux_tCO2e[dateKey]
        })
      }
    });
  }

  get_carbonDensityTable() {
    let habitatTypeMap = new Map();
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = longstandingGeoJSONRef.current.features[i];
      let habitatType = feature.feature_data_simple.HabitatType.toLowerCase();
      if (!habitatTypeMap.has(habitatType)) {
        habitatTypeMap.set(habitatType, feature);
      }
    }
    habitatTypeMap.forEach((feature, habitatType) => {
      if (feature.feature_data_simple_timeseries.C_density_tC !== undefined) {
        carbonDensityTable.push({
          habitatType: habitatType,
          property: feature.feature_data_simple_timeseries.C_density_tC[dateKey]
        })
      }
    });
  }

  get_valueTable() {
    let habitatTypeMap = new Map();
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = longstandingGeoJSONRef.current.features[i];
      let habitatType = feature.feature_data_simple.HabitatType.toLowerCase();
      if (!habitatTypeMap.has(habitatType)) {
        habitatTypeMap.set(habitatType, feature);
      }
    }
    habitatTypeMap.forEach((feature, habitatType) => {
      if (feature.feature_data_simple_timeseries.ValuePSP !== undefined) {
        valueTable.push({
          habitatType: habitatType,
          property: feature.feature_data_simple_timeseries.ValuePSP[dateKey]
        })
      }
    });
  }

  // generating value sets per landtype per metric to use as baseline shape_area = X of Y metric per landtype - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  gen_psp() {
    this.get_biodiversity_table();
    this.get_carbonflux_table();
    this.get_carbonDensityTable();
    this.get_valueTable();
    let highestValueInPSPArray = {};
    let seenLandtypes = new Set();
    pspArray = {};
    for (const metric of metrics) {
      pspArray[metric] = [];
      highestValueInPSPArray[metric] = [];
    }
    const features = longstandingGeoJSONRef.current.features;
    for (let i = 0; i < features.length; i++) {
      let landtype = features[i].feature_data_simple.HabitatType;
      let feature = features[i];
      if (seenLandtypes.has(landtype)) {
        continue;
      }
      for (const metric of metrics) {
        let selectedFeaturesMetric;
        if (feature &&
          feature.feature_data_simple_timeseries &&
          feature.feature_data_simple_timeseries[metric] &&
          feature.feature_data_simple_timeseries[metric][dateKey] !== undefined) {
          selectedFeaturesMetric = feature.feature_data_simple_timeseries[metric][dateKey];
        } else {
          continue;
        }
        let landtype_area;
        landtype_area = feature.feature_data_standard_calculated.Shape_Area;
        let landtype_psp;
        landtype_psp = selectedFeaturesMetric / landtype_area;
        if (landtype_psp === Infinity || isNaN(landtype_psp)) {
          landtype_psp = 0.01;
        }
        let pspObject = { landtype: landtype, psp: landtype_psp };
        pspArray[metric].push(pspObject);
        highestValueInPSPArray[metric].push(landtype_psp);
      }
      seenLandtypes.add(landtype);
    }
    console.log(pspArray);
    for (const metric of metrics) {
      let hVIPSP = Math.max(...highestValueInPSPArray[metric]);
    }
    return pspArray;
  }

  // polygon differential functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  checkDifferential = (geojson, selectedYearEditing) => {
    this.gen_psp();
    longstandingGeoJSONRef.current = geojson;
    this.createShapes(longstandingGeoJSONRef.current, selectedYearEditing);
  }

  // polygon render functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createShapes(geojson, selectedYearEditing) {
    let highestValueCut;
    let filteredGeojson;
    if (this.loggedInToAPI) {
      const json = geojson.features.filter(feature => {
        let deletedDataCut;
        let selectedYearEditingCut;
        let createdDataCut;
        if (feature.feature_data_standard.deleted === null) {
          deletedDataCut = null;
        } else {
          console.log(feature.feature_data_standard.deleted)
          deletedDataCut = feature.feature_data_standard.deleted.slice(0, 4);
        }
        if (selectedYearEditing === null) {
          selectedYearEditingCut = null
        } else {
          selectedYearEditingCut = selectedYearEditing.slice(0, 4);
        }
        if (selectedYearEditingCut === null) {
          createdDataCut = null;
        } else {
          createdDataCut = feature.feature_data_standard.created.slice(0, 4);
        }
        console.log(selectedYearEditingCut)
        if (deletedDataCut <= selectedYearEditingCut && deletedDataCut !== null) {
          console.log("1 deleted")
          return false;
        } else {
          if (createdDataCut > selectedYearEditingCut) {
            console.log("1 created")
            return false;
          } else {
            if (feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing] !== "000,000") {
              console.log("1 boundary changed")
              feature.geometry.coordinates = feature.feature_data_simple_timeseries.BoundaryChange[selectedYearEditing];
            }
            return true;
          }
        }
      });
      filteredGeojson = {
        type: "FeatureCollection",
        features: json
      };
    } else {
      filteredGeojson = geojson;
    }
    this.modelTransform = "";
    fmesh.position.set(0, 0, 0);
    this.modelTransform = this.calculateModelTransform();
    let highestValue = -Infinity;
    let featureWithHighestValue = null;
    for (let i = 0; i < filteredGeojson.features.length; i++) {
      const feature = filteredGeojson.features[i];
      if (feature.feature_data_simple_timeseries[selectedMetric] !== undefined &&
        feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing] !== undefined) {
        const value = feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
        if (value > highestValue) {
          highestValue = value;
          featureWithHighestValue = feature;
        }
      }
    }
    filteredGeojson.features.map((feature) => {
      const coordinates = feature.geometry.coordinates[0];
      const shape = new THREE.Shape(
        coordinates.map((coords) => {
          const x = (coords[0] + 180) / 360 * 40075016.68 * shape_scaling_factor - 20037508.34;
          const y = Math.log(Math.tan((90 + coords[1]) * Math.PI / 360)) / (Math.PI / 180);
          const yRad = y * 20037508.34 * shape_scaling_factor / 180;
          return new THREE.Vector2(x, yRad);
        })
      );
      let extrudeSettings = { depth: 1, bevelEnabled: false };
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      let habitatTypeOfSelectedYear = feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing];
      if (habitatTypeOfSelectedYear === undefined) {
        habitatTypeOfSelectedYear = feature.feature_data_simple.HabitatType[dateKey];
      } else {
        habitatTypeOfSelectedYear = feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing];
      }
      if (feature.feature_data_simple_timeseries[selectedMetric] !== undefined &&
        feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing] !== undefined) {
        selectedMetricValue = feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
      }
      const color = this.getColorForFeature(feature, selectedYearEditing);
      const material = new THREE.MeshPhongMaterial({ color: color, opacity: 1 });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.name = feature.skylark_id;
      highestValueCut = featureWithHighestValue.feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
      console.log(highestValueCut)
      mesh.scale.z = selectedMetricValue * 1;
      fmesh.add(mesh);
    });
    if (highestValueCut > 400000) {
      fullmesh.scale.z = .01;
    } else if (highestValueCut > 200000) {
      fullmesh.scale.z = .05;
    } else if (highestValueCut > 10000) {
      fullmesh.scale.z = .01;
    } else if (highestValueCut < 200) {
      fullmesh.scale.z = 10;
    } else {
      fullmesh.scale.z = 1
    }
    const box = new THREE.Box3().setFromObject(fmesh);
    const center = box.getCenter(new THREE.Vector3());
    fullmesh.add(fmesh);
    fullmesh.rotation.x = -Math.PI / 2;
    this.scene.add(fullmesh);
    fmesh.position.set(-center.x, -center.y);
    fmesh.scale.z = 0.001;
    fmesh.position.z = 0;
    this.map_repaint();
    this.init_animation();
  }

  // Function to simplify each feature in a GeoJSON FeatureCollection by a factor - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  simplifyGeoJSON(featureCollection, factor) {
    if (!featureCollection || featureCollection.type !== 'FeatureCollection' || !Array.isArray(featureCollection.features)) {
      throw new Error('Invalid GeoJSON FeatureCollection input');
    }
    const simplifiedFeatures = featureCollection.features.map(feature => {
      const simplifiedGeometry = turf.simplify(feature, { tolerance: factor, highQuality: true });
      return simplifiedGeometry;
    });
    return {
      type: 'FeatureCollection',
      features: simplifiedFeatures
    };
  }

  // remove current scene functions for adding new drawing instance only - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  removeDrawFromScene() {
    this.scene.remove(fullmesh);
  }

  // init animation - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  init_animation = () => {
    setIsLoading(false);
    this.map_repaint();
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 1 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
    }, animate_speed);
  }

  // is benchmarking function

  setEnableBenchmarking = () => {
    this.isBenchmarking = true;
  }

  // is benchmarking function

  setDisableBenchmarking = () => {
    this.isBenchmarking = false;
  }

  // is land use function

  setEnableLandUse = () => {
    this.isLandUse = true;
  }

  // is land use function

  setDisableLandUse = () => {
    this.isLandUse = false;
  }

  // is pie chart function

  serEnablePieChart = () => {
    this.isPieChart = true;
  }

  // is land use function

  setDisablePieChart = () => {
    this.isPieChart = false;
  }

  controlDown = () => {
    isCtrlDown = true;
  }

  controlUp = () => {
    isCtrlDown = false;
  }

  // polygon selection functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  raycast(point, selectedYearEditing) {
    if (isCtrlDown) {
      return;
    }
    let mouse = new THREE.Vector2();
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;
    const camInverseProjection = new THREE.Matrix4().getInverse(this.camera.projectionMatrix);
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();
    this.raycaster.set(cameraPosition, viewDirection);
    let intersects = this.raycaster.intersectObjects(this.scene.children, true);
    if (intersects.length > 0) {
      if (this.isBenchmarking) {
        this.search_benchmark_id(intersects[0].object.name, selectedYearEditing);
      }
      // if (this.isLandUse) {
      this.search_by_id(intersects[0].object.name, selectedYearEditing);
      // }
    } else {
      setIsVisible(false);
      this.resetfeatureOpacities(fmesh);
    }
  }

  // polygon selection functions raycasting - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  raycastHover(point, selectedYearEditing) {
    let mouse = new THREE.Vector2();
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;
    const camInverseProjection = new THREE.Matrix4().getInverse(this.camera.projectionMatrix);
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();
    this.raycaster.set(cameraPosition, viewDirection);
    let intersects = this.raycaster.intersectObjects(this.scene.children, true);
    if (intersects.length > 0) {
      if (this.isPieChart) {
        this.searchByUkhab(intersects[0].object.name, selectedYearEditing);
      }
    } else {
      this.resetfeatureOpacities();
    }
  }

  // polygon selection and traverse functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  searchByUkhab(skylarkId, selectedYearEditing) {
    this.map_repaint();
    fmesh.traverse((child) => {
      if (child.isMesh) {
        if (child.name === skylarkId) {
          child.material.opacity = 1;
          child.material.transparent = false;
          child.material.needsUpdate = true;
        } else {
          child.material.opacity = 0.2;
          child.material.transparent = true;
          child.material.needsUpdate = true;
        }
      }
    });
  }

  // if created a new polygon in draw tool - select that one specifically for changing landtype of - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createdNewFeatures = (newFeature, selectedYearEditing) => {
    this.search_by_id(newFeature, selectedYearEditing);
  };

  // polygon info functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  search_by_id = (skylark_id, selectedYear) => {
    for (const feature of longstandingGeoJSONRef.current.features) {
      if (feature.skylark_id === skylark_id) {
        setFoundFeature(feature);
        console.log(foundFeature);
        setStoredFeatureCurrentLandtype(feature.feature_data_simple.HabitatType);
        break;
      }
    }
    this.clickedInfoPopup();
    return foundFeature.properties;
  };

  // polygon info functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  clickedInfoPopup = () => {
    setIsVisible(true);
    let color;
    this.adjustFeatureOpacities(fmesh, foundFeature);
    const UKHAB = foundFeature.feature_data_simple.HabitatType;
    const skylark_id = foundFeature.skylark_id;
    const ParcelCarbon = foundFeature.feature_data_simple.ParcelCarbon;
    const BD_Parcel = foundFeature.feature_data_simple.BD_Parcel;
    const Shape_Area = foundFeature.feature_data_simple.Shape_Area;
    const landType = foundFeature.feature_data_simple.UKHAB;
    if (isUKHabColoursActive) {
    } else {
      color = presetColors[landType];
    }
    const propertiesHTMLHeader = `${skylark_id}`;
    setClickedInfoHeader(propertiesHTMLHeader);
  };

  // polygon repaint functions

  // Utility function to format any value as a string, handling objects specially - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  formatValue(value) {
    if (value === null || value === undefined) {
      return ''; // Handle null or undefined values
    } else if (typeof value === 'object' && !(value instanceof Date)) {
    } else if (value instanceof Date) {
      return value.toLocaleDateString(); // Format Date objects
    } else {
      return value.toString(); // Convert other types to string
    }
  }

  // Assuming foundFeature is the feature found and want to highlight - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  adjustFeatureOpacities(fmesh, foundFeature) {
    fmesh.traverse((child) => {
      if (child.isMesh) { // Ensure the child is a mesh
        if (foundFeature && child.name === foundFeature.skylark_id) {
          child.material.opacity = 1;
          child.material.needsUpdate = true;
        } else {
          child.material.opacity = 0.2;
          child.material.transparent = true;
          child.material.needsUpdate = true;
        }
      }
    });
  }

  // Utility function to repaint the map - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  resetfeatureOpacities(fmesh) {
    fmesh.traverse((child) => {
      if (child.isMesh) {
        child.material.opacity = 1;
        child.material.needsUpdate = true;
      }
    });
    this.map_repaint();
  }

  // Assuming foundFeature is the feature you've found and want to highlight - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  adjustFeatureOpacitiesPerUKHAB(landtype) {
    if (landtype === "reset") {
      this.resetfeatureOpacities(fmesh);
      this.map_repaint();
      return;
    }
    fmesh.children.forEach((child) => {
      const skylarkId = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylarkId
      );
      if (feature && landtype.includes(feature.feature_data_simple.HabitatType)) {
        child.material.opacity = 1;
        child.material.needsUpdate = true;
      } else {
        child.material.opacity = 0.2;
        child.material.transparent = true;
        child.material.needsUpdate = true;
      }
    });
    this.map_repaint();
    if (landtype.length === 0) {
      fmesh.children.forEach((child) => {
        const skylarkId = child.name;
        const feature = longstandingGeoJSONRef.current.features.find(
          (f) => f.skylark_id === skylarkId
        );
        if (feature) {
          child.visible = true;
        }
      });
      this.map_repaint();
    }
  }

  // polygon info close function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleCloseClickedInfo = (globalLandtype, selectedYearEditing) => {
    const interfeatures = longstandingGeoJSONRef.current.features;
    const currentSelectedYear = selectedYearEditing;
    newFeatureDepth = this.calculateAllMetric(foundFeature, globalLandtype);
    let newFeatureDepthSelectedMetric = newFeatureDepth[0][selectedMetric];
    console.log(newFeatureDepthSelectedMetric);
    setAcknowledgeNewFaetureNewDepth(newFeatureDepth);
    this.animate_change(foundFeature, newFeatureDepthSelectedMetric);
    this.updateJson(interfeatures, globalLandtype, currentSelectedYear, newFeatureDepth);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // update the geojson following the change of landtype - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  updateJson = (interfeatures, globalLandtype, currentSelectedYear, newFeatureDepth) => {
    const newFeature = { ...foundFeature };
    console.log(newFeature);
    newFeature.feature_data_simple = {
      ...newFeature.feature_data_simple,
    };
    const updatedHabitatType = { ...newFeature.feature_data_simple_timeseries.HabitatType };
    const years = Object.keys(updatedHabitatType).sort();
    let shouldUpdate = false;
    for (const year of years) {
      if (year === currentSelectedYear) {
        shouldUpdate = true;
      }
      if (shouldUpdate) {
        updatedHabitatType[year] = globalLandtype;
      }
    }
    const updatedTimeseries = { ...newFeature.feature_data_simple_timeseries };
    for (const year of years) {
      if (year >= currentSelectedYear) {
        updatedTimeseries.HabitatType[year] = globalLandtype;
        updatedTimeseries.ParcelCarbonFlux[year] = newFeatureDepth[0].ParcelCarbonFlux;
        updatedTimeseries.ParcelCarbon[year] = newFeatureDepth[0].ParcelCarbon;
        updatedTimeseries.Value_GBP[year] = newFeatureDepth[0].Value_GBP;
        updatedTimeseries.BD_Parcel[year] = newFeatureDepth[0].BD_Parcel;
      }
    }
    newFeature.feature_data_simple_timeseries = updatedTimeseries;
    const updatedFeatures = interfeatures.map(feature => {
      if (feature.skylark_id === foundFeature.skylark_id) {
        return newFeature;
      }
      return feature;
    });
    longstandingGeoJSONRef.current.features = updatedFeatures;
  };

  handleCloseClickedInfoButton = () => {
    this.resetfeatureOpacities(fmesh);
    setIsVisible(false);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // polygon info close cancel functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleCloseClickedInfoCancel = () => {
    this.resetfeatureOpacities(fmesh);
    setIsVisible(false);
    setClickedInfo(null);
    this.map_repaint();
    setdropdownhighlightedIndex(null);
    return null;
  };

  // dropdown list carbon change function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  calculateAllMetric = (feature, selectedValue) => {
    let selectedMetricAmountArray = [];
    let bioValue = null;
    let carbonDensityValue = null;
    let carbonFluxValue = null;
    let valueValue = null;
    for (let i = 0; i < biodiversityTable.length; i++) {
      if (biodiversityTable[i].habitatType === selectedValue) {
        bioValue = biodiversityTable[i].property;
      }
    }
    for (let i = 0; i < carbonfluxTable.length; i++) {
      if (carbonfluxTable[i].habitatType === selectedValue) {
        carbonFluxValue = carbonfluxTable[i].property;
      }
    }
    for (let i = 0; i < carbonDensityTable.length; i++) {
      if (carbonDensityTable[i].habitatType === selectedValue) {
        carbonDensityValue = carbonDensityTable[i].property;
      }
    }
    for (let i = 0; i < valueTable.length; i++) {
      if (valueTable[i].habitatType === selectedValue) {
        valueValue = valueTable[i].property;
      }
    }
    console.log(selectedMetricAmountArray)
    selectedMetricAmountArray.push({
      BD_Parcel: bioValue * (feature.feature_data_standard_calculated.Shape_Area / 10000),
      ParcelCarbon: carbonDensityValue * (feature.feature_data_standard_calculated.Shape_Area / 10000),
      ParcelCarbonFlux: carbonFluxValue * (feature.feature_data_standard_calculated.Shape_Area / 10000),
      Value_GBP: valueValue * (feature.feature_data_standard_calculated.Shape_Area)
    })
    return selectedMetricAmountArray;
  };

  // update polygon color function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  getColorForFeature = (feature, selectedYearEditing) => {
    let landTypeforcolor = "";
    landTypeforcolor = feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing];
    const colorHex = colorMap[landTypeforcolor] || this.updateColor(feature, landTypeforcolor);
    return colorHex;
  };

  // polygon color update functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  updateColor = (feature, landType) => {
    landType = landType.toLowerCase();
    const colorHex = presetColors[landType] || '#FFFFFF';
    const updatedColor = new THREE.Color(colorHex);
    let found = false;
    fmesh.traverse((child) => {
      if (child.name === feature.skylark_id) {
        child.material.color.copy(updatedColor);
        found = true;
      }
    });
    if (found) {
      colorMap[landType] = colorHex;
    }
    return colorHex;
  };

  // polygon repaint functions - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  updateColorToUKHAB = (feature, landType) => {
    const textureLoader = new THREE.TextureLoader();
    const UKHAB = landType;
    const texturePath = `${process.env.PUBLIC_URL}/ukhab/${UKHAB}.png`;
    const texture = textureLoader.load(texturePath);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(.01, .01);
    fmesh.traverse((child) => {
      if (child.name === feature.skylark_id) {
        const material = new THREE.MeshBasicMaterial({ map: texture });
        child.material = material;
      }
    });
    this.map_repaint();
  };

  // polygon animation function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  animate_change = (feature, growth) => {
    this.map_repaint();
    fmesh.traverse((child) => {
      if (child.name === feature.skylark_id) {
        new TWEEN.Tween(child.scale)
          .to({ z: growth }, animate_speed)
          .easing(TWEEN.Easing.Cubic.InOut)
          .onUpdate(() => {
          })
          .start();
      }
    });
  };

  // camera fly to function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  cameraFlyToLatLong = (latitude, longitude) => {
    this.map.flyTo({
      center: [longitude, latitude],
      zoom: 15,
      essential: true,
      speed: 3
    });
  };

  // camera fly to north - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  cameraFlyToNorth = (latitude, longitude) => {
    this.map.flyTo({
      bearing: 0,
      essential: true,
      speed: 3
    });
  };

  // swap map base function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  swapMapBase = (style) => {
    if (style === 'Satellite') {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
      console.log("satellite");
    }
    if (style === 'Map') {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
      console.log("map")
    }
  }

  // zoom based object hiding function (hide) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  hideSmallShapes() {
    this.scene.children.forEach(child => {
      if (child.type === "Object3D") {
        for (let i = 0; i < child.children[0].children.length; i++) {
          if (child.children[0].children[i].geometry.boundingSphere.radius < 100) {
            child.children[0].children[i].scale.z = 0.0001;
          }
        }
      }
    });
  }

  // zoom based object hiding function (show) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  showSmallShapes() {
    this.scene.children.forEach(child => {
      if (child.type === "Object3D") {
        for (let i = 0; i < child.children[0].children.length; i++) {
          if (child.children[0].children[i].geometry.boundingSphere.radius < 100) {
            child.children[0].children[i].scale.z = 1;
          }
        }
      }
    });
  }

  // 2d view - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  set2DView() {
    setIsLoading(false);
    setIs2DViewActive(true);
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 0.01 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
      console.log('Animation started');
    }, animate_speed);
    this.scene.add(this.templight);
    console.log(this.loggedInToAPI)
    if (!this.loggedInToAPI) {
      this.map.flyTo({
        zoom: 5,
        essential: true,
        speed: .2,
        pitch: 0
      });
    } else {
      console.log('logged in');
      this.map.flyTo({
        zoom: 13,
        essential: true,
        speed: .2,
        pitch: 0
      });
    }
  }

  // 3d view - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  set3DView(selectedYearEditing) {
    setIsLoading(false);
    setIs2DViewActive(false);
    this.addRegularColours(selectedYearEditing);
    setTimeout(() => {
      new TWEEN.Tween(fmesh.scale)
        .to({ z: 1 }, animate_speed)
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => {
          setIsLoading(false);
        });
      console.log('Animation started');
    }, animate_speed);
    this.scene.remove(this.templight);
    if (!this.loggedInToAPI) {
      this.map.flyTo({
        zoom: 5,
        essential: true,
        speed: .2,
        pitch: 60
      });
    } else {
      console.log('logged in');
      this.map.flyTo({
        zoom: 13,
        essential: true,
        speed: .2,
        pitch: 60
      });
    }
    this.map.dragRotate = true;
  }

  // add UKHAB colours - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  addUKHABColours() {
    const textureLoader = new THREE.TextureLoader();
    fmesh.traverse(async (child) => {
      const skylark_id = child.name;
      const features = longstandingGeoJSONRef.current.features;
      const matchingFeature = features.find(feature => feature.skylark_id === skylark_id);
      if (matchingFeature) {
        const UKHAB = matchingFeature.feature_data_simple.HabitatType;
        const texturePath = `${process.env.PUBLIC_URL}/ukhab/${UKHAB}.png`;
        const texture = textureLoader.load(texturePath);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(.01, .01);
        const material = new THREE.MeshBasicMaterial({ map: texture });
        child.material = material;
      }
    });
    this.map_repaint();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  // add regular colours - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  addRegularColours(selectedYearEditing) {
    fmesh.traverse((child) => {
      const skylark_id = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylark_id
      );
      if (feature) {
        const color = this.getColorForFeature(feature, selectedYearEditing);
        const material = new THREE.MeshPhongMaterial({ color: color, opacity: 1 });
        child.material = material;
      }
    });
    this.map_repaint();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  // set landtype function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  setLandtype(landtypes) {
    fmesh.children.forEach((child) => {
      if (landtypes === "Hide all") {
        child.visible = false;
      }
      const skylarkId = child.name;
      const feature = longstandingGeoJSONRef.current.features.find(
        (f) => f.skylark_id === skylarkId
      );
      if (feature && landtypes.includes(feature.feature_data_simple.HabitatType)) {
        child.visible = true;
      } else {
        child.visible = false;
      }
    });
    this.map_repaint();
    if (landtypes.length === 0) {
      fmesh.children.forEach((child) => {
        const skylarkId = child.name;
        const feature = longstandingGeoJSONRef.current.features.find(
          (f) => f.skylark_id === skylarkId
        );
        if (feature) {
          child.visible = true;
        }
      });
      console.log("landtypes length is 0")
      this.map_repaint();
    }
  }

  // map repaint function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  map_repaint = () => {
    let id = setInterval(() => {
      this.map.triggerRepaint();
    }, 1);
    setTimeout(() => {
      clearInterval(id);
    }, animate_speed * 2);
  }

  // render function - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  render(gl, matrix) {
    TWEEN.update();
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      this.modelTransform.rotateX
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      this.modelTransform.rotateY
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      this.modelTransform.rotateZ
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        this.modelTransform.translateX,
        this.modelTransform.translateY,
        this.modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          this.modelTransform.scale,
          -this.modelTransform.scale,
          this.modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);
    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);

  }

}

export default SkylarkPolygonLayer;