import React from 'react';

const LoadingSpinner = ({ isLoading, loadedPercentage }) => {
  return (
    isLoading ? (
      <div className='loading'>
        <div className='loading_bar'>
          <div
            className='loading_bar_fill'
            style={{ width: `${loadedPercentage}%` }}
          ></div>
        </div>
        <div className='loading_text'>
          <p>{loadedPercentage}%</p>
        </div>
      </div>
    ) : null
  );
};

export default LoadingSpinner;
