import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeApp from './HomeMap';  // Your Home component
import Callback from './components/Callback';  // Your Callback component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeApp />} />
        <Route path="/callback" element={<HomeApp />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
