import React from 'react';
import { RiPieChartFill } from "react-icons/ri";

const PieChart = ({ PieChartselectionCancelOnClick, isViewingPieChart }) => {

  return (
    <div className={`land_types ${isViewingPieChart ? 'selected' : ''}`} onClick={PieChartselectionCancelOnClick}>
      <button>
        <RiPieChartFill className="leftMenuIcons"/> Pie Chart</button>
    </div>
  )
};

export default PieChart;
