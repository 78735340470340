import React from 'react';
import { useState } from 'react';
import { MdAccountCircle } from "react-icons/md";
import logo from '../images/logo.png';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Squeeze as Hamburger } from 'hamburger-react'

const TopMenu = ({
  authClicked,
  openAboutPanel,
  openPrivacyPanel,
  openTacPanel,
  openInverstorsPanel,
  openMobileSignIn
}) => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setOpen(!isOpen); // This toggles the visual state of the hamburger menu
  };

  return (
    <>
      <div className='top_menu'>

        <div className='title'>
          <img src={logo} className='logo' alt='Skylark Logo' />
        </div>

        <BrowserView>
          <div className='auth' onClick={authClicked}><MdAccountCircle /></div>
          <div className='menuItems'>
            <div onClick={openAboutPanel} className='menuItem'>About Skylark</div>
            <div onClick={openPrivacyPanel} className='menuItem'>Privacy</div>
            <div onClick={openTacPanel} className='menuItem'>Terms & Conditions</div>
            <div onClick={openInverstorsPanel} className='menuItem'>Investors</div>
          </div>
        </BrowserView>

        <MobileView>
          <div className='burgermenu' onClick={toggleMobileMenu}>
            <Hamburger className='burgermenuinner' color="#29645D" size="20" toggled={isOpen} toggle={setOpen} />
          </div>
        </MobileView>

      </div>
      {mobileMenuOpen && (
        <div className="top_menu_Mobile">
          <div onClick={openMobileSignIn} className='menuItemMobile'>Sign In</div>
          <div onClick={openAboutPanel} className='menuItemMobile'>About Skylark</div>
          <div onClick={openPrivacyPanel} className='menuItemMobile'>Privacy</div>
          <div onClick={openTacPanel} className='menuItemMobile'>Terms & Conditions</div>
          <div onClick={openInverstorsPanel} className='menuItemMobile'>Investors</div>
        </div>
      )}
    </>
  );
};

export default TopMenu;