import React from 'react';
import { BsBarChartFill } from "react-icons/bs";
import { PiScalesFill } from "react-icons/pi";

const CompareButtonMobile = ({
    isComparing,
    handleCompareClick
}) => {
    return (
        <div onClick={handleCompareClick}>
            <button className='MobileIconButton'>
                <PiScalesFill className="leftMenuIconsMobile" />
                <p>Compare</p>
            </button>
        </div>
    );
};

export default CompareButtonMobile;