import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { presetColors } from '../../libs/colours';

const Benchmarking = ({
  benchmarkingVisible,
  benchmarkingData,
  selectedMetric,
  isBenchmarking,
  longstandingGeoJSONRef,
  originalGeoJSONforComparingRef,
  selectedYearEditing
}) => {
  
  const chartRef = useRef(null); // useRef to hold the chart instance
  const selectedLandtype = useRef(null);
  let landtype;

  const allFeatureLandtypeMetric = (geojson, skylark_id) => {
    const newfeatures = longstandingGeoJSONRef.current.features;
    let totalSum = 0;
    console.log(geojson)
    const features = geojson.features;
    features.forEach(feature => {
      if (feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing] === landtype) {
        const metric = feature.feature_data_simple[selectedMetric];
        if (metric !== undefined) {
          totalSum += metric;
        }
      }
    });
    return totalSum;
  }

  const singleFeatureLandtypeMetric = (skylark_id) => {
    const features = longstandingGeoJSONRef.current.features;
    let metric = 0;
    features.forEach(feature => {
      if (feature.skylark_id === skylark_id) {
        metric = feature.feature_data_simple[selectedMetric];
      }
    });
    return metric;
  }

  useEffect(() => {
    if (isBenchmarking && benchmarkingData) {
      const bar1 = singleFeatureLandtypeMetric(benchmarkingData);
      const bar2 = allFeatureLandtypeMetric(longstandingGeoJSONRef.current, benchmarkingData);
      const labels = ['Estate', 'National'];
      const dataValues = [bar1, bar2];
      const ctx = document.getElementById('barBenchmarkingSinglechart');

      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy the existing chart before creating a new one
      }

      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            data: dataValues,
            backgroundColor: [
              presetColors[landtype], // Dynamic color for Bar 1
              presetColors[landtype]  // Dynamic color for Bar 2
            ],
            hoverOffset: 4
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 18,
                },
              },
            },
            y: {
              display: false,
              grid: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 18,
                },
              },
            }
          }
        }
      });
    }

    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [benchmarkingData, isBenchmarking, selectedMetric]); // Removed longstandingGeoJSONRef.current from the dependencies array

  return (
    benchmarkingVisible && (
      <>
        <b>{selectedLandtype.current}</b>
        <canvas id='barBenchmarkingSinglechart' height={100} className="barchart" />
        <p>
          UK soil contains about 10 billion tonnes
          of carbon, roughly equal to 80 years of
          annual greenhouse gas emissions.
          <br /><br />
          Source: Gov.uk
        </p>
      </>
    )
  );
};

export default Benchmarking;
