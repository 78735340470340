import React from 'react';
import { RiPieChartFill } from "react-icons/ri";

const PieChartButtonMobile = ({
    PieChartselectionCancelOnClick,
}) => {

    return (
        <div>
            <button className='MobileIconButton' onClick={PieChartselectionCancelOnClick}>
                <RiPieChartFill className="leftMenuIconsMobile" />
                <p>Pie Chart</p>
            </button>
        </div>
    )
};

export default PieChartButtonMobile;
