import React, { useState, useEffect } from 'react';

const UKHABcoloursTab = ({
    globalRefs,
    globalState,
    setIsLoading,
    isUKHabColoursActive,
    setIsUKHabColoursActive,
}) => {
    const [is2DViewActive, setIs2DViewActive] = useState(false);
    const [isMapViewActive, setIsMapViewActive] = useState(false);

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    let selectedYearEditing = globalState.selectedYearEditing;

    const toggleUKHABView = () => {
        setIs2DViewActive(!is2DViewActive);
        if (is2DViewActive) {
            setIsUKHabColoursActive(false);
            polygonCustomLayerRef.current.addRegularColours(selectedYearEditing);
        } else {
            setIsUKHabColoursActive(true);
            polygonCustomLayerRef.current.addUKHABColours();
        }
    };

    return (
        <>
            <div className="general_nav_ukhab">
                <button className="gnav_button" onClick={toggleUKHABView}>
                    <p className='ukhabtitle'>UKHab</p>
                    <label className="ukhabtoggle_switch" >
                        <input type="checkbox" className='checkbox' checked={is2DViewActive}/>
                        <span className="slider round"></span>
                    </label>
                </button>
            </div>
        </>
    );
};

export default UKHABcoloursTab;
