import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAye14_tZbBhD_l1_bfERSpiRbBkqX52uM",
  authDomain: "skylark-96d12.firebaseapp.com",
  databaseURL: "https://skylark-96d12-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "skylark-96d12",
  storageBucket: "skylark-96d12.appspot.com",
  messagingSenderId: "946076535842",
  appId: "1:946076535842:web:8112b4e3ed0e1426078e7b",
  measurementId: "G-L4SMRS3PKH"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firestore
const firestore = getFirestore(app);

// Function to fetch and list all documents in a Firestore collection
async function listDocuments(collectionName) {
  try {
    const docsCollection = collection(firestore, collectionName);
    const snapshot = await getDocs(docsCollection);
    const documents = [];
    snapshot.forEach(doc => {
      documents.push({ id: doc.id, data: doc.data() });
    });
    return documents; // Now returning the array of documents
  } catch (error) {
    console.error('Error getting documents:', error);
  }
}

// Export everything that may be needed elsewhere
export { auth, firestore, listDocuments };
