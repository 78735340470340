import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SkylarkPolygonLayer from '../core/skylarkPolygonLayer';
import { landCoverClasses } from '../../libs/ukhablandtypes.js';
import { center } from '@turf/turf';
import {
  fmesh,
  fullmesh,
  loading_timer_count
} from '../core/globalVariables.js';
import { IoClose } from "react-icons/io5";

const LoadMenu = ({
  isMenuLoadClick,
  closeLoadMenu,
  files,
  handleItemClick,
  globalRefs,
  globalState,
  ResetMapLoadedData,
  mapLoaded,
  loggedInToAPI,
  setLoggedInToAPI,
  ResetPolygonLayer,
  selectedMetric,
  setSelectedMetric,
  setLoadedPercentage,
  ResetPolygonLayerPublic
}) => {

  const [mapData, setMapData] = useState([]);
  const [mapDetails, setMapDetails] = useState(null);
  const [clickedMapId, setClickedMapId] = useState(null);
  const [initFetch, setInitFetch] = useState(0);

  const setIsLoading = globalState.setIsLoading;
  const mapRef = globalRefs.mapRef;
  const polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  const longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  const originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;
  const setisMenuLoadClick = globalState.setisMenuLoadClick;
  let centroidRef = globalRefs.centroidRef;

  const setCurrentMapId = globalState.setCurrentMapId;

  let uniqueTextureNamesArray = globalState.uniqueTextureNamesArray;
  let setUniqueTextureNamesArray = globalState.setUniqueTextureNamesArray;

  const uniqueTextureNames = new Set();

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await axios.get('https://harrierapi9697.site/map', { withCredentials: true });
        setMapData(response.data);
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };
    if (mapLoaded) {
      setTimeout(() => {

        // fetchMapData();

      }, 1000);
      setLoadedPercentage(30);
    }
  }, [mapLoaded]);

  const fetchMapDetailsSelected = async (id) => {
    setClickedMapId(id);
  };

  const loadDataBtn = () => {
    fetchMapDetails(clickedMapId);
    setisMenuLoadClick(false);
    setIsLoading(true);
  };

  let i = 0;

  const fetchMapDetails = async (id) => {
    setCurrentMapId(id);
    setisMenuLoadClick(false);
    setLoadedPercentage(40);
    setTimeout(() => {
      setLoadedPercentage(60);
    }, 2500);
    setTimeout(() => {
      setLoadedPercentage(80);
    }, 5000);
    setTimeout(() => {
      setLoadedPercentage(100);
    }, 7500);
    setLoggedInToAPI(true);

      // const response = await axios.get(`https://harrierapi9697.site/map/${id}`, { withCredentials: true });

      async function fetchMapDetails(mapId) {
        try {
          // Check if the token is stored in local storage
          const token = localStorage.getItem('token');
          console.log(token)
          if (!token) {
            throw new Error('No token in local storage');
          } else {
            console.log("we have a token: " + token)
            const response = await fetch(`https://harrierapi9697.site/map/${id}?token=${encodeURIComponent(token)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
              throw new Error(`Error fetching map details: ${response.statusText}`);
            }
            const geoJsonData = await response.json();
            loggedInDataLoad(geoJsonData);
          }
        } catch (error) {
          console.error('Error:', error);
          loadPublicData();
        }
      }

      fetchMapDetails(id);

  };

  const loggedInDataLoad = async (geoJsonData) => {

      console.log(geoJsonData);
      centroidRef.current = center(geoJsonData);
      setMapDetails(geoJsonData);
      longstandingGeoJSONRef.current = geoJsonData;
      longstandingGeoJSONRef.current.centroidRef = centroidRef.current.geometry.coordinates;
      let newDataArray = [];
      let invalidFeatures = [];
      if (i == 0) {
        originalGeoJSONforComparingRef.current = JSON.parse(JSON.stringify(geoJsonData));
        i = 1;
      }
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let feature = { ...longstandingGeoJSONRef.current.features[i] };
        delete feature.type;
        const coordinates = feature.geometry.coordinates[0];
        if (coordinates.length < 4) {
          invalidFeatures.push(feature);
        } else {
          newDataArray.push(feature);
        }
      }
      longstandingGeoJSONRef.current.features = newDataArray;
      console.log("Invalid features:", invalidFeatures);
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        const UKHABHabitatType = longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType;
        uniqueTextureNames.add(UKHABHabitatType);
      }
      setUniqueTextureNamesArray([...uniqueTextureNames]);
      setLoadedPercentage(60);
      if (mapRef.current) {
        if (initFetch === 0) {
          polygonCustomLayerRef.current = new SkylarkPolygonLayer(
            'Polygons',
            centroidRef.current.geometry.coordinates[0],
            centroidRef.current.geometry.coordinates[1],
            longstandingGeoJSONRef.current,
            globalRefs,
            globalState
          );
          mapRef.current.addLayer(polygonCustomLayerRef.current);
          ResetPolygonLayer(globalRefs, globalState);
        } else {
          fmesh.children.forEach((child) => {
            child.geometry.dispose();
            child.material.dispose();
          });
          fullmesh.children.forEach((child) => {
            fullmesh.remove(child);
          });
          fmesh.children.forEach((child) => {
            fmesh.remove(child);
          });
          fmesh.children.length = 0;
          fullmesh.children.length = 0;
          mapRef.current.removeLayer('Polygons');
          polygonCustomLayerRef.current = new SkylarkPolygonLayer(
            'Polygons',
            centroidRef.current.geometry.coordinates[0],
            centroidRef.current.geometry.coordinates[1],
            longstandingGeoJSONRef.current,
            globalRefs,
            globalState
          );
          mapRef.current.addLayer(polygonCustomLayerRef.current);
          ResetPolygonLayer(globalRefs, globalState);
        }
        mapRef.current.on('mousedown', (e) => {
          if (e.originalEvent.button === 0) {
            polygonCustomLayerRef.current.raycast(e.point, false);
          }
        });
      }
    }

  // Load public data if the user is not logged in

  const loadPublicData = async () => {
    setLoggedInToAPI(false);
    try {
      const response = await axios.get('/data/modified_geojson-8.json');
      setLoadedPercentage(60);
      const geoJsonData = response.data;
      centroidRef.current = center(geoJsonData);
      longstandingGeoJSONRef.current = geoJsonData;
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let feature = { ...longstandingGeoJSONRef.current.features[i] };
        const coordinates = feature.geometry.coordinates[0][0];
        feature.geometry.coordinates[0] = coordinates;
      }
      polygonCustomLayerRef.current = new SkylarkPolygonLayer(
        'Polygons',
        centroidRef.current.geometry.coordinates[1] + .4,
        centroidRef.current.geometry.coordinates[0] + 3.4,
        geoJsonData,
        globalRefs,
        globalState,
      );
      let newDataArray = [];
      let invalidFeatures = [];
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let feature = { ...longstandingGeoJSONRef.current.features[i] };
        delete feature.type;
        const coordinates = feature.geometry.coordinates[0]; // Assuming coordinates are already nested correctly
        // Check if the feature has fewer than 4 coordinate pairs
        if (coordinates.length < 4) {
          invalidFeatures.push(feature);
        } else {
          // Otherwise, process the feature as usual
          newDataArray.push(feature);
        }
      }
      // Update the longstandingGeoJSONRef with valid features only
      longstandingGeoJSONRef.current.features = newDataArray;
      console.log("Invalid features:", invalidFeatures);
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let UKHABHabitatType = longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType;
        longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType = UKHABHabitatType.toLowerCase();
        UKHABHabitatType = UKHABHabitatType.toLowerCase();
        uniqueTextureNames.add(UKHABHabitatType);
      }
      setUniqueTextureNamesArray([...uniqueTextureNames]);
      if (mapRef.current) {
        mapRef.current.addLayer(polygonCustomLayerRef.current);
        mapRef.current.on('mousedown', (e) => {
          if (e.originalEvent.button === 0) {
            polygonCustomLayerRef.current.raycast(e.point, false);
          }
        });
      }
    } catch (error) {
      console.error('Error loading public data:', error);
    }
  };

  // Reset the map data when the user logs out

  useEffect(() => {
    if (mapData && initFetch === 0 && mapLoaded) {
      fetchMapDetails('2fcbd066-578f-421a-9676-b0b50eddcea1');
      setInitFetch(1);
    }
  }, [mapData, initFetch, mapLoaded]);

  return (
    isMenuLoadClick ? (
      <div className='file_dropdown_list'>
        <div className='APIMapLoad'>
          <div className="closeBtnCont">
            <span className="closeLoadBtn" onClick={closeLoadMenu}>
              Cancel
            </span>
            <span className="openLoadBtn" onClick={loadDataBtn}>
              Open
            </span>
          </div>
          <div className='APIMapLoadInnter'>
            <ul className='map-list'>
              {mapData.map((map, index) => (
                <li
                  key={index}
                  onClick={() => fetchMapDetailsSelected(map.id)}
                  className={`map-item ${clickedMapId === map.id ? 'highlighted' : ''}`}
                >
                  <div className='map-list-info-cont'>
                    <div className="LoadMenuscreenshot"></div>
                    <div className='map-item-name'>
                      <span className='map-name'>{map.title}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ) : null
  )
};

export default LoadMenu;
