import React from 'react';
import { MdLandslide } from "react-icons/md";

const LandUseButtonMobile = ({
    isLanduse,
    handleLandUseClick
}) => {
    return (
        <div onClick={handleLandUseClick}>
            <button className='MobileIconButton'>
                <MdLandslide className="leftMenuIconsMobile" />
                <p>Land Use</p>
            </button>
        </div>
    );
};

export default LandUseButtonMobile;