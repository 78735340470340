import React from "react";
import LandTypes from './leftMenuButtons/landTypesButton.js';
import PieChart from './leftMenuButtons/pieChartButton.js';
import Modify from './leftMenuButtons/modifyButton.js';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import MetricDropdown from './leftMenuButtons/metricDropdown.js';
import YearDropdown from './leftMenuButtons/yearDropdown.js';
import LandUseButton from "./leftMenuButtons/landUseButton.js";
import CompareButton from "./leftMenuButtons/compareButton.js";
import BenchmarkButton from "./leftMenuButtons/benchmarkButton.js";
import { isMobile } from 'react-device-detect';

import { MdLandslide } from "react-icons/md";
import { PiScalesFill } from "react-icons/pi";
import { HiPencil } from "react-icons/hi";

const LeftMenu = ({
  isDrawing,
  isSelectingLandtypes,
  LandtypeSelectionCancelOnClick,
  setisSelectingLandtypes,
  beginDrawOnClick,
  endDrawOnClick,
  endDrawCancelOnClick,
  toggleDrawer,
  isLeftDrawerOpen,
  isLeftSubDrawerOpen,
  selectedMetric,
  setSelectedMetric,
  resetMetric,
  PieChartselectionCancelOnClick,
  ForecastCancelOnClick,
  ModifySelectionCancelOnClick,
  selectedYearEditing,
  setSelectedYearEditing,
  handleYearChange,
  isViewingPieChart,
  isViewingForecast,
  isModifying,
  handleModifyClick,
  isLanduse,
  handleLandUseClick,
  isComparing,
  handleCompareClick,
  isBenchmarking,
  handleBenchmarkClick,
  globalState,
  globalRefs,
  loggedInToAPI
}) => {

  const metricOptions = [
    { label: 'Carbon', value: 'ParcelCarbon' },
    { label: 'Carbon Flux', value: 'ParcelCarbonFlux' },
    { label: 'Capital Value', value: 'Value_GBP' },
    { label: 'Biodiversity', value: 'BD_Parcel' },
  ];

  const publicMetricOptions = [
    { label: 'C02 Total', value: 'carbon_density' },
    { label: 'C02 by Fuel Stations', value: 'co2fuelstations' },
    { label: 'C02 by Trains', value: 'co2trains' },
    { label: 'C02 by Buildings', value: 'co2buildings' },
    { label: 'C02 by Airports', value: 'co2airports' },
    { label: 'C02 by Farms', value: 'co2farms' },
    { label: 'C02 by Refineries', value: 'co2refineries' },
  ];

  const mobileStatus = isMobile ? "mobile" : "not-mobile";

  return (
    <div className='leftMenuButton'>

      <button className={`togglerDrawInside ${mobileStatus}`} onClick={toggleDrawer}>
        <IoIosArrowForward />
      </button>

      <Drawer
        open={isLeftDrawerOpen}
        direction='left'
        className='leftMenu'
        enableOverlay={false}
        duration={150}
      >

        <h2>Choose Metric</h2>

        <MetricDropdown
          metricOptions={metricOptions}
          resetMetric={resetMetric}
          setSelectedMetric={setSelectedMetric}
          selectedMetric={selectedMetric}
          globalState={globalState}
          publicMetricOptions={publicMetricOptions}
        />

        <h2>Select Year</h2>

        <YearDropdown
          isModifying={isModifying}
          selectedYearEditing={selectedYearEditing}
          handleYearChange={handleYearChange}
          handleModifyClick={handleModifyClick}
          isLanduse={isLanduse}
          handleLandUseClick={handleLandUseClick}
          isComparing={isComparing}
          handleCompareClick={handleCompareClick}
          isBenchmarking={isBenchmarking}
          handleBenchmarkClick={handleBenchmarkClick}
          globalState={globalState}
          globalRefs={globalRefs}
        />

        <LandTypes
          LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
          isSelectingLandtypes={isSelectingLandtypes}
        />

        <PieChart
          PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
          isViewingPieChart={isViewingPieChart}
        />

        {loggedInToAPI ? (
          <Modify
            ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
            isViewingForecast={isViewingForecast}
            handleModifyClick={handleModifyClick}
            isModifying={isModifying}
          />
        ) : (
          // INACTIVE MODIFY BUTTON
          <div className={`land_types ${isModifying ? 'selected' : ''}`}>
            <button disabled="true">
              <HiPencil className="leftMenuIcons" /> Boundaries
            </button>
          </div>
        )
        }

        {loggedInToAPI ? (
          <LandUseButton
            handleLandUseClick={handleLandUseClick}
            isLanduse={isLanduse}
          />
        ) : (
          // INACTIVE LAND USE BUTTON
          <div className={`land_types ${isLanduse ? 'selected' : ''}`}>
            <button disabled="true">
              <MdLandslide className="leftMenuIcons" /> Land Use
            </button>
          </div>
        )
        }

        {loggedInToAPI ? (
          <CompareButton
            handleCompareClick={handleCompareClick}
            isComparing={isComparing}
          />
        ) : (
          // INACTIVE COMPARE BUTTON
          <div className={`land_types ${isComparing ? 'selected' : ''}`}>
            <button disabled="true">
              <PiScalesFill className="leftMenuIcons" /> Compare
            </button>
          </div>
        )
        }

        {/* <BenchmarkButton
          handleBenchmarkClick={handleBenchmarkClick}
          isBenchmarking={isBenchmarking}
        /> */}

        {isLeftSubDrawerOpen ? (
          null
        ) : (
          <button className='togglerDrawOutside' onClick={toggleDrawer}><IoIosArrowBack /></button>
        )
        }

      </Drawer>

    </div>
  );
};

export default LeftMenu;