import React, { useState } from "react";
import 'react-modern-drawer/dist/index.css';
import logo from '../../images/logo.png';
import { IoIosArrowDown } from "react-icons/io";

const InvestorsPanel = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    // Helper function to get the appropriate class name for tab content
    const getTabContentClassName = (tabIndex) => {
        return activeTab === tabIndex ? "tabContentActive" : "tabContent";
    };

    // Helper function to get the appropriate class name for the arrow
    const getArrowClassName = (tabIndex) => {
        return activeTab === tabIndex ? "arrow_down arrow_rotate" : "arrow_down";
    };

    return (
        <div className='about'>
            <div className="about_inner">
                <div className="about_inner_top_section">
                    <img src={logo} className='logo' alt='Skylark Logo' />
                    <h2>Skylark Investors</h2>
                    <p>Apple’s Privacy Policy describes how Apple collects, uses, and shares your personal data.
                        In addition to this Privacy Policy, we provide data and privacy information embedded in our
                        products and certain features that ask to use your personal data. This product-specific
                        information is accompanied by our Data & Privacy Icon.
                    </p>
                    <button className="about_button">Download PDF Privacy Statement</button>

                    <div className="tabsystem">
                        <button className={"abouttab_button"} onClick={() => handleTabClick(1)}>What is personal data at Skylark?
                            <div className={getArrowClassName(1)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(1)}>
                            <p>
                                Apple’s Privacy Policy describes how Apple collects, uses, and shares your personal data.
                                In addition to this Privacy Policy, we provide data and privacy information embedded in our
                                products and certain features that ask to use your personal data. This product-specific
                                information is accompanied by our Data & Privacy Icon.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(2)}>Your privacy rights at Skylark
                            <div className={getArrowClassName(2)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(2)}>
                            <p>
                                Apple’s Privacy Policy describes how Apple collects, uses, and shares your personal data.
                                In addition to this Privacy Policy, we provide data and privacy information embedded in our
                                products and certain features that ask to use your personal data. This product-specific
                                information is accompanied by our Data & Privacy Icon.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(3)}>Personal data Skylark collects from you
                            <div className={getArrowClassName(3)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(3)}>
                            <p>
                                Apple’s Privacy Policy describes how Apple collects, uses, and shares your personal data.
                                In addition to this Privacy Policy, we provide data and privacy information embedded in our
                                products and certain features that ask to use your personal data. This product-specific
                                information is accompanied by our Data & Privacy Icon.
                            </p>
                        </div>

                        <button className={"abouttab_button"} onClick={() => handleTabClick(4)}>Personal data Skylark receives from other sources
                            <div className={getArrowClassName(4)}>
                                <IoIosArrowDown />
                            </div>
                        </button>
                        <div className={getTabContentClassName(4)}>
                            <p>
                                Apple’s Privacy Policy describes how Apple collects, uses, and shares your personal data.
                                In addition to this Privacy Policy, we provide data and privacy information embedded in our
                                products and certain features that ask to use your personal data. This product-specific
                                information is accompanied by our Data & Privacy Icon.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvestorsPanel;
