import React, { useState, useEffect, useRef } from "react";
import { HiPencil } from "react-icons/hi";
import { BsBarChartFill } from "react-icons/bs";
import { IoMdEye } from "react-icons/io";
import { MdLandslide } from "react-icons/md";

const YearDropdown = ({
    options,
    resetMetric,
    setSelectedMetric,
    selectedMetric,
    isModifying,
    selectedYearEditing,
    handleYearChange,
    handleModifyClick,
    isLanduse,
    handleLandUseClick,
    isComparing,
    handleCompareClick,
    isBenchmarking,
    handleBenchmarkClick,
    globalState,
    globalRefs
}) => {
    const [clicked, setClicked] = useState(false);
    const [uniqueYears, setUniqueYears] = useState([]);
    const [carbonDensityKeys, setCarbonDensityKeys] = useState([]);

    let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    let loggedInToAPI = globalState.loggedInToAPI;

    useEffect(() => {
        if (longstandingGeoJSONRef.current) {
            // Set to store unique keys
            let uniqueKeys = new Set();
            // Iterate through each feature
            longstandingGeoJSONRef.current.features.forEach(feature => {
                if (feature.feature_data_simple_timeseries && feature.feature_data_simple_timeseries.ParcelCarbon) {
                    // Get keys of ParcelCarbon for the current feature
                    let keys = Object.keys(feature.feature_data_simple_timeseries.ParcelCarbon);
                    // Add each key to the set
                    keys.forEach(key => {
                        uniqueKeys.add(key); // Store full dateKey in the set
                    });
                }
            });
            // Convert Set to array and set the unique years state
            setUniqueYears([...uniqueKeys]);
        }
    }, [longstandingGeoJSONRef.current]);

    useEffect(() => {
        if (clicked) {
            resetMetric();
            setClicked(false);
        }
    }, [selectedMetric, clicked, resetMetric]);

    // Function to handle year change
    const handleChange = (event) => {
        const selectedYear = event.target.value;
        // Log entire ParcelCarbon keys for the selected year
        if (longstandingGeoJSONRef.current) {
            console.log(`Logging unique ParcelCarbon keys for year ${selectedYear}:`);
            // Set to store unique ParcelCarbon keys for the selected year
            let uniqueKeys = new Set();
            // Iterate through each feature
            longstandingGeoJSONRef.current.features.forEach(feature => {
                if (feature.feature_data_simple_timeseries && feature.feature_data_simple_timeseries[selectedMetric]) {
                    // Get keys of ParcelCarbon for the current feature
                    let keys = Object.keys(feature.feature_data_simple_timeseries[selectedMetric]);
                    // Check if any key starts with the selected year
                    keys.forEach(key => {
                        if (key.startsWith(selectedYear)) {
                            uniqueKeys.add(key); // Add full dateKey to the Set
                        }
                    });
                }
            });
            // Convert Set to array and log the unique ParcelCarbon keys for the selected year
            const uniqueKeysArray = [...uniqueKeys];
            console.log(uniqueKeysArray);
            // Update state with the unique ParcelCarbon keys for the selected year
            setCarbonDensityKeys(uniqueKeysArray);
        }
        // Call the provided handleYearChange function
        handleYearChange(event);
    };

    // Generate options for the dropdown menu
    const yearOptions = uniqueYears.map(key => (
        <option key={key} value={key}>
            {key.substring(0, 4)}
        </option>
    ));


    return (
        <div className="select-container">
            {loggedInToAPI ? (
                <>
                    <select value={selectedYearEditing} onChange={handleChange} className="yearSelectionModify">
                        {yearOptions}
                    </select>
                    <div className="select-arrow-year"></div>
                </>
            ) : (
                <select value={selectedYearEditing} className="yearSelectionModify" disabled>
                    <option value="2024-01-01T00:00:00+00:00">2024</option>
                </select>
            )}
        </div>
    );
};

export default YearDropdown;
