import React from 'react';
import { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import SaveOptions from './topSubMenuComponents/saveOptions.js';
import SavedStatus from './topSubMenuComponents/savedStatus.js';
import SearchBar from './topSubMenuComponents/SearchBar.js';
import Geolocate from './topSubMenuComponents/geolocate.js';
import Compass from './topSubMenuComponents/compass.js';

const TopSubMenu = ({
    openFilenameInputModal,
    saveCurrentDataSetClick,
    loadMenuClick,
    isHasSaved,
    savedFilename,
    isHasSavedCurrentData,
    loadedFileName,
    isFilenameInputOpen,
    closeFilenameInputModal,
    newFilename,
    handleFilenameInputChange,
    saveDataWithFilenameClick,
    isMenuLoadClick,
    closeLoadMenu,
    filenames,
    handleItemClick,
    mapboxTokenRef,
    globalState,
    globalRefs,
    openAboutPanel,
    openPrivacyPanel,
    openTacPanel,
    openInverstorsPanel,
    isInAboutPanel,
    isInInvestorsPanel,
    isInPrivacyPanel,
    isInTacPanel,
    resetAboutPanels,
    isInSettings,
    mapbearing,
    loggedInToAPI
}) => {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

    const backToMap = () => {
        resetAboutPanels();
    }

    return (
        <div className={`top_Submenu ${isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? 'inabout' : ''}`}>

            <BrowserView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={backToMap}>Back to Map</button>
                    </div>
                ) :
                    <div>
                        <SaveOptions
                            openFilenameInputModal={openFilenameInputModal}
                            saveCurrentDataSetClick={saveCurrentDataSetClick}
                            loadMenuClick={loadMenuClick}
                            loggedInToAPI={loggedInToAPI}
                        />

                        <SavedStatus
                            isHasSaved={isHasSaved}
                            savedFilename={savedFilename}
                            isHasSavedCurrentData={isHasSavedCurrentData}
                            loadedFileName={loadedFileName}
                        />

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />

                        <div className='top_Submenu__right'>
                            <div className='top_Submenu__right__menu'>
                                <div className='top_Submenu__right__menu__load'>
                                    <p>Create your own data map with Skylark</p>
                                    <button
                                        className='top_Submenu__right__menu__load__button'
                                    >
                                        Find out more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </BrowserView>
            <MobileView>

                {isInAboutPanel || isInInvestorsPanel || isInPrivacyPanel || isInTacPanel || isInSettings ? (
                    <div className='topSubmenuBack'>
                        <button className='topSubmenuBackButton' onClick={resetAboutPanels}>Back to Map</button>
                    </div>
                ) : (
                    <div>
                        {/* <SaveOptions
                                openFilenameInputModal={openFilenameInputModal}
                                saveCurrentDataSetClick={saveCurrentDataSetClick}
                                loadMenuClick={loadMenuClick}
                            /> */}

                        {/* <SavedStatus
                                isHasSaved={isHasSaved}
                                savedFilename={savedFilename}
                                isHasSavedCurrentData={isHasSavedCurrentData}
                                loadedFileName={loadedFileName}
                            /> */}

                        <SearchBar
                            mapboxTokenRef={mapboxTokenRef}
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Geolocate
                            globalRefs={globalRefs}
                            globalState={globalState}
                        />

                        <Compass
                            globalRefs={globalRefs}
                            globalState={globalState}
                            mapbearing={mapbearing}
                        />
                    </div>
                )}

            </MobileView>

        </div >

    );
};

export default TopSubMenu;