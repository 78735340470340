import {
  fmesh,
  fullmesh,
  loading_timer_count,
  map,
  creating
} from './globalVariables.js';

import drawDeleteHandler from './initializeMapboxDraw.js';

const ResetMap = async (globalRefs, globalState) => {

  let mapRef = globalRefs.mapRef;
  let drawRef = globalRefs.drawRef;
  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setIsLanduse = globalState.setIsLanduse;
  let setisLeftSubDrawerOpen = globalState.setisLeftSubDrawerOpen;

  let hasCreatedNewPolygonInModify = globalState.hasCreatedNewPolygonInModify;
  let setHasCreatedNewPolygonInModify = globalState.setHasCreatedNewPolygonInModify;

  // mapRef.current.off('draw.create');
  // mapRef.current.off('draw.delete');
  // mapRef.current.off('draw.update');
  // drawRef.current.deleteAll();
  mapRef.current.removeControl(drawRef.current);

  let uniqueTextureNamesArray = globalState.uniqueTextureNamesArray;
  let setUniqueTextureNamesArray = globalState.setUniqueTextureNamesArray;

  const uniqueTextureNames = new Set();

  setTimeout(() => {
    console.log(mapRef.current);
    fmesh.children.forEach((child) => {
      child.geometry.dispose();
      child.material.dispose();
    });
    fullmesh.children.forEach((child) => {
      fullmesh.remove(child);
    });
    fmesh.children.forEach((child) => {
      fmesh.remove(child);
    });
    fmesh.children.length = 0;
    fullmesh.children.length = 0;
    console.log(hasCreatedNewPolygonInModify)

    polygonCustomLayerRef.current.checkDifferential(longstandingGeoJSONRef.current, selectedYearEditing);

    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      const UKHABHabitatType = longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType;
      uniqueTextureNames.add(UKHABHabitatType);
    }
    setUniqueTextureNamesArray([...uniqueTextureNames]);
    console.log(uniqueTextureNamesArray)

    if (hasCreatedNewPolygonInModify !== "") {
      console.log("created new polygon in modify")
      setIsLanduse(true);
      polygonCustomLayerRef.current.createdNewFeatures(hasCreatedNewPolygonInModify, selectedYearEditing);
      setHasCreatedNewPolygonInModify("");
      setisLeftSubDrawerOpen(true);
    }

  }, loading_timer_count);
}

export default ResetMap;