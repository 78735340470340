async function saveCurrentDataSet(
  globalRefs,
  globalFileStates,
  globalState,
  base64image) {

  let setsavedFilename = globalState.setsavedFilename;
  let setisHasSaved = globalState.setisHasSaved;
  let setIsLoading = globalState.setIsLoading;
  let setIsFilenameInputOpen = globalFileStates.setIsFilenameInputOpen;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;

  let dataUpload = longstandingGeoJSONRef.current;

  const currentMapId = globalState.currentMapId;

  // Create newDataArray with nested coordinates
  let newDataArray = [];
  let invalidFeatures = [];

  for (let i = 0; i < dataUpload.features.length; i++) {
    let feature = { ...dataUpload.features[i] };
    delete feature.type;
    const coordinates = feature.geometry.coordinates[0]; // Assuming coordinates are already nested correctly

    // Check if the feature has fewer than 4 coordinate pairs
    if (coordinates.length < 4) {
      console.error(`Feature ${i} has an incorrect number of coordinate pairs:`, coordinates);
      invalidFeatures.push(feature);
    } else {
      // Otherwise, process the feature as usual
      newDataArray.push(feature);
    }
  }

  console.log("Invalid features:", invalidFeatures);
  console.log("Valid features:", newDataArray);

  const srid = 4326;  // Replace with the appropriate SRID

  let response;

  if (currentMapId === 'bca62613-2fb7-46da-bc1a-a743213e31c6') {
    alert("Save as a new File first");
  } {

    response = await fetch(`http://localhost:3001/api/maps/${currentMapId}/features?srid=${srid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDataArray),
      credentials: 'include',
    });
  }

  const result = await response.json();
  console.log(result);

  if (response.ok) {
    console.log('Map feature updated successfully');
  } else {
    console.error('Error updating:', result);
  }

  setisHasSaved(true);
  setTimeout(() => {
    setisHasSaved(false);
    setIsFilenameInputOpen(false);
  }, 3000);
};

export default saveCurrentDataSet;