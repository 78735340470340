import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { HiPencil } from "react-icons/hi";
import { BsBarChartFill } from "react-icons/bs";
import { IoMdEye } from "react-icons/io";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import RenderedPTags from "./leftSubMenuComponents/RenderedPTags.js";
import PieChart from "./leftSubMenuComponents/pieChart.js";
import ModifyPanel from "./leftSubMenuComponents/ModifyPanel.js";
import RenderedPTagsWithKey from "./leftSubMenuComponents/RenderedPTagsWithKey.js";
import Chart from 'chart.js/auto';
import { presetColors } from '../../libs/colours.js';
import { MdLandslide } from "react-icons/md";
import DropdownList from './leftSubMenuComponents/dropdownList.js';

const LeftSubMenu = ({
  isDrawing,
  beginDrawOnClick,
  endDrawOnClick,
  endDrawCancelOnClick,
  isSelectingLandtypes,
  toggleLeftSubDrawer,
  isLeftSubDrawerOpen,
  handleLandtypePTagClick,
  selectedLandTypes,
  isViewingPieChart,
  longstandingGeoJSONRef,
  originalGeoJSONforComparingRef,
  isViewingForecast,
  selectedYearEditing,
  setSelectedYearEditing,
  ModifySelectionCancelOnClick,
  isModifying,
  isComparing,
  isBenchmarking,
  handleYearChange,
  handleModifyClick,
  handleCompareClick,
  handleBenchmarkClick,
  selectedMetric,
  benchmarkingData,
  uniqueTextureNamesArray,
  isUKHabColoursActive,
  handleLandUseClick,
  isLanduse,
  polygonCustomLayerRef,
  isVisible,
  dropdownhighlightedIndex,
  setdropdownhighlightedIndex,
  setIsLanduse,
  Benchmarking,
  benchmarkingVisible,
  piechartRef,
  handleEnlargePieButtonClick,
  setcomparingLandtypeSelectedOption,
  resetLeftSubMenu,
  setisLeftSubDrawerOpen,
  storedfeaturecurrentlandtype,
  setStoredFeatureCurrentLandtype,
  temporaryGeoJSONIntendedForSaving,
  setTemporaryGeoJSONIntendedForSaving
}) => {

  let dateKey = selectedYearEditing;

  const chartRef = useRef(null); // useRef to hold the chart instance
  const selectedLandtype = useRef(null);
  let landtype;

  const allFeatureLandtypeMetric = (geojson, skylark_id) => {
    const newfeatures = longstandingGeoJSONRef.current.features;
    let metric = 0;
    newfeatures.forEach(feature => {
      if (feature.skylark_id === skylark_id) {
        landtype = feature.feature_data_simple_timeseries.HabitatType[dateKey]
        selectedLandtype.current = landtype;
      }
    });
    const features = geojson.features;
    let totalSum = 0;
    features.forEach(feature => {
      if (feature.feature_data_simple_timeseries.HabitatType[dateKey] === landtype) {

        let metric;

        if (feature &&
          feature.feature_data_simple_timeseries &&
          feature.feature_data_simple_timeseries.carbon_density &&
          feature.feature_data_simple_timeseries.carbon_density[dateKey] !== undefined) {
          metric = feature.feature_data_simple_timeseries.carbon_density[dateKey];
        } else {
          console.log("Some properties are undefined or not accessible.");
        }

        if (metric !== undefined) {
          totalSum += metric;
        }
      }
    });
    return totalSum;
  }

  const singleFeatureLandtypeMetric = (skylark_id) => {
    const features = longstandingGeoJSONRef.current.features;
    let metric = 0;
    features.forEach(feature => {
      if (feature.skylark_id === skylark_id) {
        metric = feature.feature_data_simple_timeseries.carbon_density[selectedYearEditing];
      }
    });
    return metric;
  }

  useEffect(() => {
    if (isBenchmarking && benchmarkingData) {
      console.log(benchmarkingData)
      const bar1 = singleFeatureLandtypeMetric(benchmarkingData);
      const bar2 = allFeatureLandtypeMetric(longstandingGeoJSONRef.current, benchmarkingData);
      const labels = ['Estate', 'National'];
      const dataValues = [bar1, bar2];
      const ctx = document.getElementById('barchart');
      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy the existing chart before creating a new one
      }
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            data: dataValues,
            backgroundColor: [
              presetColors[landtype], // Dynamic color for Bar 1
              presetColors[landtype]  // Dynamic color for Bar 2
            ],
            hoverOffset: 4
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 18,
                },
              },
            },
            y: {
              display: false,
              grid: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 18,
                },
              },
            }
          }
        }
      });
    }
    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [benchmarkingData, isBenchmarking, selectedMetric]); // Removed longstandingGeoJSONRef.current from the dependencies array

  const conditionalClassName = benchmarkingData.length !== 0 ? "barchart" : "otherClass";

  return (
    <div>
      <Drawer
        open={isLeftSubDrawerOpen}
        direction='left'
        className='leftSubMenu'
        enableOverlay={false}
        duration={150}
      >

        <div onClick={toggleLeftSubDrawer} className="leftSubMenu_cancel">
          <button>
            Cancel
          </button>
        </div>

        <div>
          {isSelectingLandtypes ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Land Key</h1>
                <div className='land_type_list_general'>
                  <RenderedPTags
                    selectedLandTypes={selectedLandTypes}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    isUKHabColoursActive={isUKHabColoursActive}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          {isViewingPieChart ? (
            <div className="genericSubMenuHelpBox">
              <div className="genericSubMenuHelpBoxinner">
                <h1>Pie Chart</h1>
                <div className="piechartdiv">
                  <PieChart
                    geojson={longstandingGeoJSONRef.current}
                    isViewingPieChart={isViewingPieChart}
                    selectedMetric={selectedMetric}
                    isUKHabColoursActive={isUKHabColoursActive}
                    handleLandtypePTagClick={handleLandtypePTagClick}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    piechartRef={piechartRef}
                    handleEnlargePieButtonClick={handleEnlargePieButtonClick}
                    selectedYearEditing={selectedYearEditing}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )}
        </div>

        <div>
          <div>
            {isModifying ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Modify Map</h1>
                  {isModifying &&
                    <ModifyPanel
                      endDrawOnClick={endDrawOnClick}
                      endDrawCancelOnClick={endDrawCancelOnClick}
                      selectedYearEditing={selectedYearEditing}
                    />}
                  <p>
                    <h3>Tool bar</h3>
                    Lorem ipsum dolor sit amet conse
                    ctetur adipiscing elit sed do eiusmod
                    tempor incididunt ut labore et dolore
                    magna aliqua ut enim ad minim veni
                    am quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commo
                    do consequat. Duis aute irure dolorin
                    reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla.
                    <h3>Tool bar</h3>
                    Paragraph Heading Style
                    Lorem ipsum dolor sit amet conse
                    ctetur adipiscing elit sed do eiusmod
                    tempor incididunt ut labore et dolore
                    magna aliqua ut enim ad minim veni
                    am quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commo
                    do consequat. Duis aute irure dolorin
                    reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla.
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isComparing ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Land Type Key</h1>
                  <p>
                    <RenderedPTagsWithKey
                      selectedLandTypes={selectedLandTypes}
                      handleLandtypePTagClick={handleLandtypePTagClick}
                      isUKHabColoursActive={isUKHabColoursActive}
                      uniqueTextureNamesArray={uniqueTextureNamesArray}
                      setcomparingLandtypeSelectedOption={setcomparingLandtypeSelectedOption}
                    />
                  </p>
                </div>
              </div>
            ) : (
              null
            )}
            {isBenchmarking ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Benchmarking</h1>
                  <div class="scrollingBenchmarking">
                    <p>
                      Lorem ipsum dolor sit amet conse
                      ctetur adipiscing elit sed do eiusmod
                      tempor incididunt ut labore et dolore
                      magna aliqua.
                    </p>
                    <canvas id='barchart' className='barchart' height={100} />
                    <p>
                      UK soil contains about 10 billion tonnes
                      of carbon, roughly equal to 80 years of
                      annual greenhouse gas emissions.
                      <br /><br />
                      Source: Gov.uk
                    </p>

                    {/* {benchmarkingData.length !== 0 && ( */}
                    <Benchmarking
                      benchmarkingVisible={benchmarkingVisible}
                      benchmarkingData={benchmarkingData}
                      selectedMetric={selectedMetric}
                      isBenchmarking={isBenchmarking}
                      longstandingGeoJSONRef={longstandingGeoJSONRef}
                      selectedYearEditing={selectedYearEditing}
                    />
                    {/* )} */}
                  </div>
                </div>

              </div>
            ) : (
              null
            )}
            {isLanduse ? (
              <div className="genericSubMenuHelpBox">
                <div className="genericSubMenuHelpBoxinner">
                  <h1>Change Land Use</h1>
                  <p>
                    Lorem ipsum dolor sit amet conse
                    ctetur adipiscing elit sed do eiusmod
                    tempor incididunt ut labore et dolore
                    magna aliqua.
                  </p>
                  <DropdownList
                    isVisible={isVisible}
                    polygonCustomLayerRef={polygonCustomLayerRef}
                    selectedYearEditing={selectedYearEditing}
                    dropdownhighlightedIndex={dropdownhighlightedIndex}
                    setdropdownhighlightedIndex={setdropdownhighlightedIndex}
                    uniqueTextureNamesArray={uniqueTextureNamesArray}
                    isUKHabColoursActive={isUKHabColoursActive}
                    isLandUse={isLanduse}
                    setIsLanduse={setIsLanduse}
                    resetLeftSubMenu={resetLeftSubMenu}
                    setisLeftSubDrawerOpen={setisLeftSubDrawerOpen}
                    storedfeaturecurrentlandtype={storedfeaturecurrentlandtype}
                    setStoredFeatureCurrentLandtype={setStoredFeatureCurrentLandtype}
                    temporaryGeoJSONIntendedForSaving={temporaryGeoJSONIntendedForSaving}
                    setTemporaryGeoJSONIntendedForSaving={setTemporaryGeoJSONIntendedForSaving}
                  />
                </div>
              </div>
            ) : (
              null
            )}
          </div>
        </div>

        <button className='togglerDrawOutside' onClick={toggleLeftSubDrawer}>
          <IoIosArrowBack />
        </button>
      </Drawer>
    </div>
  );
};

export default LeftSubMenu;