import { IoMdLocate } from "react-icons/io";
import { isMobile } from 'react-device-detect';

const Geolocate = ({globalRefs, globalState}) => {

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    
    const mobileStatus = isMobile ? "mobile" : "not-mobile";

    return (
        <div className={`geolocate ${mobileStatus}`}>
            <button
                className='geolocate__button'
                onClick={() => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            const { latitude, longitude } = position.coords;
                            console.log('Latitude: ', latitude);
                            console.log('Longitude: ', longitude);
                            polygonCustomLayerRef.current.cameraFlyToLatLong(latitude, longitude);
                        });
                    } else {
                        alert('Geolocation is not supported by this browser.');
                    }
                }}
            >
                <IoMdLocate className="geoIcon" color="#07EA77" size={25}  />
            </button>
        </div>
    );
}

export default Geolocate;