import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const ClickedInfo = ({
  isVisible,
  polygonCustomLayerRef,
  clickedInfo,
  clickedInfoHeader,
  clickedInfoHeaderMobile,
  globalState,
  foundFeature,
  longstandingGeoJSONRef
}) => {
  // State to manage the visibility of the more information section
  const [showMore, setShowMore] = useState(false);

  let selectedMetric = globalState.selectedMetric;
  let selectedYearEditing = globalState.selectedYearEditing;

  let acknowledgeNewFaetureNewDepth = globalState.acknowledgeNewFaetureNewDepth;

  const mobileStatus = isMobile ? "mobile" : "not-mobile";

  // clickedInfoHeader

  const [modalValue, setModalValue] = useState(null);
  const [modalMetric, setModalMetric] = useState(null);
  const [modalOther, setModalOther] = useState(null);
  const [modalHabitat, setModalHabitat] = useState(null);
  const [modalSkylarkId, setModalSkylarkId] = useState(null);

  const geojson = longstandingGeoJSONRef.current;

  let clickedProperties = null;
  let clickedHabitat = null;

  function roundToTwoDecimals(value) {
    if (typeof value === 'number') {
      return Math.round(value * 100) / 100;
    }
    return value;
  }

  function formatFeatureData(featureData) {
    return (
      <table className='clickedInfoTable'>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(featureData).map(([key, value]) => (
            <tr key={key}>
              <td className="clickedInfoLeftHandColumn">{key}:</td>
              <td className="clickedInfoRightHandColumn">{roundToTwoDecimals(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  useEffect(() => {
    if (clickedInfoHeader !== null) {
      for (let i = 0; i < geojson.features.length; i++) {
        if (geojson.features[i].skylark_id === clickedInfoHeader) {
          clickedProperties = geojson.features[i].feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
          clickedHabitat = geojson.features[i].feature_data_simple_timeseries.HabitatType[selectedYearEditing];
          clickedProperties = Math.round(clickedProperties);
          setModalValue(JSON.stringify(clickedProperties, null, 2));
          let featureDataSimple = geojson.features[i].feature_data_simple;
          let formattedData = formatFeatureData(featureDataSimple);
          setModalOther(formattedData);
          const truncatedClickedHabitat = clickedHabitat.substring(0, 25);
          if (truncatedClickedHabitat.length < clickedHabitat.length) {
            setModalHabitat(truncatedClickedHabitat + '...');
          } else {
            setModalHabitat(truncatedClickedHabitat);
          }
          const truncatedSkylarkId = geojson.features[i].skylark_id.substring(0, 15);
          setModalSkylarkId(truncatedSkylarkId + '...');
        }
      }
    }
    selectedMetric = selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1);
    selectedMetric = selectedMetric.replace(/_/g, ' ');
    setModalMetric(selectedMetric);
  }, [clickedInfoHeader, selectedMetric, selectedYearEditing, acknowledgeNewFaetureNewDepth]);

  return (
    isVisible && (
      <div className={`clicked_info ${mobileStatus}`}>
        <MobileView>
          <IoClose className="clicked_info_close_icon_mobile" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
          {clickedInfoHeader !== null && (
            <div className='clicked_info_inner' dangerouslySetInnerHTML={{ __html: clickedInfoHeaderMobile }} />
          )}
        </MobileView>
        <BrowserView>
          <div className='clicked_info_header'>
            <div className='clicked_info_close'>
              <IoClose className="clicked_info_close_icon" size={20} onClick={() => polygonCustomLayerRef.current.handleCloseClickedInfoCancel()} />
            </div>
          </div>
          {clickedInfoHeader !== null && (
            <div className='clicked_info_inner'>
              <div className='clickedInfo1'>
                <p className='clicked_info_innerTitle'>
                  Land type:
                </p>
                <p className='clicked_info_innerHabitat'>
                  {modalHabitat}
                </p>
              </div>
              <div className='clickedInfo2'>
                <p className='clicked_info_parcelRefTitle'>
                  Parcel Reference:
                </p>
                <p className='clicked_info_parcelRef'>
                  {modalSkylarkId}
                </p>
              </div>
              <div className='clickedInfo3'>
                <div className='clicked_info_metric'>
                  <p>
                    {modalMetric}:
                  </p>
                </div>
                <div className='clicked_info_value'>
                  <p>
                    {modalValue}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className='clicked_info_more_button'
            onClick={() => setShowMore(!showMore)} // Toggle the showMore state
          >
            {showMore ? "Show Less" : "Show More"}
          </div>
          {showMore && (
            <div className='clicked_info_inner'>
              <div className='clicked_info_other'>
                {modalOther}
              </div>
            </div>
          )}
        </BrowserView>
      </div>
    )
  );
};

export default ClickedInfo;
