import ResetMapLoadedData from '../core/resetMapLoadedData';

async function loadData(filename, globalRefs, globalFileStates, globalState) {

    let setisMenuLoadClick = globalState.setisMenuLoadClick;
    let setloadedFileName = globalFileStates.setloadedFileName;
    let loadedDataGeojson = globalRefs.loadedDataGeojson;

    setisMenuLoadClick(false);
    setloadedFileName(filename);
    try {
        const response = await fetch(`https://harrierapi9697.online/download/${filename}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '13429697',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data)
        loadedDataGeojson.current = data;
        ResetMapLoadedData(globalRefs, globalState);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
    }
};

export default loadData;
