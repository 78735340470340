import {
    fmesh,
    fullmesh,
    loading_timer_count
  } from './globalVariables.js';
  
  const ResetMapLoadedData = async (globalRefs, globalState) => {
  
    let mapRef = globalRefs.mapRef;
    let drawRef = globalRefs.drawRef;
    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
    let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
    let selectedYearEditing = globalState.selectedYearEditing;
    let loadedDataGeojson = globalRefs.loadedDataGeojson;
  
    let isDrawing = globalState.isDrawing;
    
    if(isDrawing){
      mapRef.current.removeControl(drawRef.current);
      mapRef.current.off('draw.create');
      mapRef.current.off('draw.delete');
    }
  
    // console.log(GeojSONAmendsCompRef.current);
  
    setTimeout(() => {
      fmesh.children.forEach((child) => {
        child.geometry.dispose();
        child.material.dispose();
      });
      fullmesh.children.forEach((child) => {
        fullmesh.remove(child);
      });
      fmesh.children.forEach((child) => {
        fmesh.remove(child);
      });
      fmesh.children.length = 0;
      fullmesh.children.length = 0;
      
      polygonCustomLayerRef.current.checkDifferential(longstandingGeoJSONRef.current, selectedYearEditing);
      
    }, loading_timer_count);
  }
  
  export default ResetMapLoadedData;