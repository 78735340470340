import React, { useEffect, useState } from 'react';

function ImageGallery({ handleItemClick, closeLoadMenu }) {
    const [imageUrls, setImageUrls] = useState([]);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '', created: '', selected: false });

    useEffect(() => {
        fetchImages();
    }, []);

    async function fetchImages() {
        try {
            const response = await fetch('https://harrierapi9697.online/images');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const pngImages = data.urls.filter((url) => url.name.includes('.png'));
            setImageUrls(pngImages);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }

    const handleItemClickTemp = (image) => {
        let clickedData = image.name.replace('.png', '.json');
        console.log('Clicked image:', clickedData);
        console.log(clickedData)
        handleItemClick(clickedData);
    };

    const handleImageContainerClick = (imageUrl) => {
        setSelectedImage({ ...imageUrl, selected: true });
        setImageUrls(imageUrls.map(img => ({
            ...img,
            selected: img.name === imageUrl.name
        })));
    };

    return (
        <div className="loadDataMap">
            <p>Load a data map</p>
            <div className="imageGalleryContainer">
                <div className="imageGallery" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '50px' }}>
                    {imageUrls.map((imageUrl, index) => {
                        const imageTitle = imageUrl.name.replace('.png', '');
                        const isSelected = selectedImage.name === imageUrl.name;
                        return (
                            <div
                                key={index}
                                className={`imageGalleryImageContainer ${isSelected ? 'selected' : ''}`} // Apply conditional class name
                                onClick={() => handleImageContainerClick(imageUrl)}
                            >
                                <img
                                    className='imageGalleryImage'
                                    src={imageUrl.url}
                                    alt={imageUrl.name}
                                />
                                <div className='imageGalleryImageCreated'>Created: {imageUrl.created}</div>
                                <div className='imageGalleryImageTitle'>{imageTitle}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='bottomBarLoad'>
                <div onClick={closeLoadMenu} className='closeLoadBtn'>Close</div>
                <div onClick={() => handleItemClickTemp(selectedImage)} className='openLoadBtn'>Open</div>
            </div>
        </div>
    );
}

export default ImageGallery;
