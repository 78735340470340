
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './App.css';
import './Custom.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

// icons
import { MdAccountCircle } from "react-icons/md";

// draw
import ResetMap from './components/core/resetMap.js';
import ResetPolygonLayer from './components/core/resetPolygonLayer.js';
import ResetPolygonLayerPublic from './components/core/resetPolygonLayerPublic.js';
import initializeMapboxDraw from './components/core/initializeMapboxDraw.js';
import ResetMapYear from './components/core/resetMapYear.js';
import ResetMapLoadedData from './components/core/resetMapLoadedData.js';

// dataManagement
import fetchInitData from './components/dataManagement/fetchInitData.js';
import fetchFilenames from './components/dataManagement/fetchFilenames.js';
import saveDataWithFilename from './components/dataManagement/saveDataWithFilename.js';
import saveCurrentDataSet from './components/dataManagement/saveCurrentDataSet.js';
import loadData from './components/dataManagement/loadData.js';
import ImageGallery from './components/dataManagement/fetchImages.js';

// ui
import TopMenu from './components/ui/topMenu.js';
import LoadingSpinner from './components/ui/loadingSpinner.js';
import DropdownList from './components/ui/leftSubMenuComponents/dropdownList.js';
import ClickedInfo from './components/ui/clickedInfo.js';
import TopSubMenu from './components/ui/topSubMenu.js';
import LeftMenu from './components/ui/leftMenu.js';
import LeftSubMenu from './components/ui/leftSubMenu.js';
import ModifyPanel from './components/ui/leftSubMenuComponents/ModifyPanel.js';
import ComparePanel from './components/ui/leftSubMenuComponents/comparePanel.js';
import GeneralNav from './components/ui/generalNav.js';
import LoadMenu from './components/ui/loadMenu.js';
import FilenameInputModal from './components/ui/filenameInputModal.js';
import Benchmarking from './components/ui/benchmarking.js';
import SettingsPanel from './components/ui/leftSubMenuComponents/settingsPanel.js';
import AboutPanel from './components/ui/pages/about.js';
import PrivacyPanel from './components/ui/pages/privacy.js';
import TacPanel from './components/ui/pages/termsandconditions.js';
import InvestorsPanel from './components/ui/pages/investorsPanel.js';
import UKHABcoloursTab from './components/ui/ukhabColoursTab.js';
import YearDropdown from './components/ui/leftMenuButtons/yearDropdown.js';
import LargePieChart from './components/ui/leftSubMenuComponents/largePieChart.js';
import ControlsNav from './components/ui/controlsNav.js';

// auth
import Login from './components/auth/login.js';

import {
  loading_timer_count,
  useGlobalRefs,
  useGlobalFileStates,
  useGlobalState,
  fmesh
} from './components/core/globalVariables.js';

import html2canvas from 'html2canvas';

import { useSpring, animated } from '@react-spring/web';
import DraggableDrawer from './components/ui/draggableDrawer.js';
import DraggableSubDrawer from './components/ui/draggableSubDrawer.js';

const HomeApp = () => {

  const globalRefs = useGlobalRefs();
  const globalFileStates = useGlobalFileStates();
  const globalState = useGlobalState();

  let mapRef = globalRefs.mapRef;
  let drawRef = globalRefs.drawRef;
  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let mapContainerRef = globalRefs.mapContainerRef;
  let originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;
  let mapboxTokenRef = globalRefs.mapboxTokenRef;
  let piechartRef = globalRefs.piechartRef;

  let isFilenameInputOpen = globalFileStates.isFilenameInputOpen;
  let setIsFilenameInputOpen = globalFileStates.setIsFilenameInputOpen;
  let newFilename = globalFileStates.newFilename;
  let setNewFilename = globalFileStates.setNewFilename;
  let newFileScreenShot = globalFileStates.newFileScreenShot;
  let setNewFileScreenShot = globalFileStates.setNewFileScreenShot;
  let loadedFileName = globalFileStates.loadedFileName;

  let clickedInfo = globalState.clickedInfo;
  let clickedInfoHeader = globalState.clickedInfoHeader;
  let clickedInfoHeaderMobile = globalState.clickedInfoHeaderMobile;
  let isVisible = globalState.isVisible;
  let isDrawing = globalState.isDrawing;
  let setisDrawing = globalState.setisDrawing;
  let isLoading = globalState.isLoading;
  let setIsLoading = globalState.setIsLoading;
  let filenames = globalState.filenames;
  let isMenuLoadClick = globalState.isMenuLoadClick;
  let setisMenuLoadClick = globalState.setisMenuLoadClick;
  let isHasSaved = globalState.isHasSaved;
  let isHasSavedCurrentData = globalState.isHasSavedCurrentData;
  let savedFilename = globalState.savedFilename;
  let openAuth = globalState.openAuth;
  let setopenAuth = globalState.setopenAuth;
  let isSelectingLandtypes = globalState.isSelectingLandtypes;
  let setisSelectingLandtypes = globalState.setisSelectingLandtypes;
  let isLeftDrawerOpen = globalState.isLeftDrawerOpen;
  let setisLeftDrawerOpen = globalState.setisLeftDrawerOpen;
  let isLeftSubDrawerOpen = globalState.isLeftSubDrawerOpen;
  let setisLeftSubDrawerOpen = globalState.setisLeftSubDrawerOpen;
  let selectedLandTypes = globalState.selectedLandTypes;
  let setSelectedLandTypes = globalState.setSelectedLandTypes;
  let selectedMetric = globalState.selectedMetric;
  let setSelectedMetric = globalState.setSelectedMetric;
  let PiechatSelectionCancelOnClick = globalState.PiechatSelectionCancelOnClick;
  let setPiechatSelectionCancelOnClick = globalState.setPiechatSelectionCancelOnClick;
  let isViewingPieChart = globalState.isViewingPieChart;
  let setisViewingPieChart = globalState.setisViewingPieChart;
  let isViewingForecast = globalState.isViewingForecast;
  let setisViewingForecast = globalState.setisViewingForecast;
  let selectedYearEditing = globalState.selectedYearEditing;
  let setSelectedYearEditing = globalState.setSelectedYearEditing;
  let isModifying = globalState.isModifying;
  let setisModifying = globalState.setisModifying;
  let isComparing = globalState.isComparing;
  let setisComparing = globalState.setisComparing;
  let isBenchmarking = globalState.isBenchmarking;
  let setisBenchmarking = globalState.setisBenchmarking;
  let hasCreatedNewPolygonInModify = globalState.hasCreatedNewPolygonInModify;
  let setHasCreatedNewPolygonInModify = globalState.setHasCreatedNewPolygonInModify;
  let benchmarkingData = globalState.benchmarkingData;
  let setBenchmarkingData = globalState.setBenchmarkingData;
  let benchmarkingVisible = globalState.benchmarkingVisible;
  let setBenchmarkingVisible = globalState.setBenchmarkingVisible;
  let isInSettings = globalState.isInSettings;
  let setIsInSettings = globalState.setIsInSettings;
  let isInAboutPanel = globalState.isInAboutPanel;
  let setIsInAboutPanel = globalState.setIsInAboutPanel;
  let isInPrivacyPanel = globalState.isInPrivacyPanel;
  let setIsInPrivacyPanel = globalState.setIsInPrivacyPanel;
  let isInTacPanel = globalState.isInTacPanel;
  let setIsInTacPanel = globalState.setIsInTacPanel;
  let isInInvestorsPanel = globalState.isInInvestorsPanel;
  let setIsInInvestorsPanel = globalState.setIsInInvestorsPanel;
  let dropdownhighlightedIndex = globalState.dropdownhighlightedIndex;
  let setdropdownhighlightedIndex = globalState.setdropdownhighlightedIndex;
  let is2DViewActive = globalState.is2DViewActive;
  let setIs2DViewActive = globalState.setIs2DViewActive;
  let isUKHabColoursActive = globalState.isUKHabColoursActive;
  let setIsUKHabColoursActive = globalState.setIsUKHabColoursActive;
  let uniqueTextureNamesArray = globalState.uniqueTextureNamesArray;
  let setUniqueTextureNamesArray = globalState.setUniqueTextureNamesArray;
  let mapbearing = globalState.mapbearing;
  let setMapBearing = globalState.setMapBearing;
  let isLanduse = globalState.isLanduse;
  let setIsLanduse = globalState.setIsLanduse;
  let isViewingLargePieChart = globalState.isViewingLargePieChart;
  let setisViewingLargePieChart = globalState.setisViewingLargePieChart;
  let comparingLandtypeSelectedOption = globalState.comparingLandtypeSelectedOption;
  let setcomparingLandtypeSelectedOption = globalState.setcomparingLandtypeSelectedOption;
  let isDraggableSubDrawerOpen = globalState.isDraggableSubDrawerOpen;
  let setisDraggableSubDrawerOpen = globalState.setisDraggableSubDrawerOpen;
  let storedfeaturecurrentlandtype = globalState.storedfeaturecurrentlandtype;
  let setStoredFeatureCurrentLandtype = globalState.setStoredFeatureCurrentLandtype;
  let isCtrlDown = globalState.isCtrlDown;
  let setIsCtrlDown = globalState.setIsCtrlDown;
  let loadedAmount = globalState.loadedAmount;
  let setLoadedAmount = globalState.setLoadedAmount;
  let isControlsNavOpen = globalState.isControlsNavOpen;
  let setIsControlsNavOpen = globalState.setIsControlsNavOpen;
  let foundFeature = globalState.foundFeature;
  let setFoundFeature = globalState.setFoundFeature;

  let loggedInToAPI = globalState.loggedInToAPI;
  let setLoggedInToAPI = globalState.setLoggedInToAPI;

  let mapLoaded = globalState.mapLoaded;
  let setMapLoaded = globalState.setMapLoaded;

  let loadedPercentage = globalState.loadedPercentage;
  let setLoadedPercentage = globalState.setLoadedPercentage;

  let temporaryGeoJSONIntendedForSaving = globalState.temporaryGeoJSONIntendedForSaving;
  let setTemporaryGeoJSONIntendedForSaving = globalState.setTemporaryGeoJSONIntendedForSaving;

  let zoomAmount;

  // - - - - -
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      const getQueryParams = () => {
          const queryParams = new URLSearchParams(location.search);
          const token = queryParams.get('token');
          if (token) {
              // Store the token in local storage
              localStorage.setItem('token', token);
              sessionStorage.setItem('tokenSession', token);
              // Clean up URL to remove token
              console.log(token);
              navigate(location.pathname, { replace: true });
          }
      };

    getQueryParams();
  }, [location.search, navigate]);

  // - - - - -

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        setIsCtrlDown(true);
        polygonCustomLayerRef.current.controlDown();
      }
    };

    const handleKeyUp = (event) => {
      if (!event.ctrlKey) {
        setIsCtrlDown(false);
        polygonCustomLayerRef.current.controlUp();
      }
    };

    if (mapLoaded) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    }

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [mapLoaded]);

  // Function to open the filename input modal
  const openFilenameInputModal = () => {
    setIsFilenameInputOpen(true);
  };

  // Function to close the filename input modal
  const closeFilenameInputModal = () => {
    setIsFilenameInputOpen(false);
    setNewFilename('');
  };

  // Function to handle filename input change
  const handleFilenameInputChange = (e) => {
    setNewFilename(e.target.value);
  };

  // Adjust the click handler to also pass the base64image
  const saveDataWithFilenameClick = () => {
    setIsLoading(true);
    html2canvas(mapContainerRef.current, { scale: 1 }).then((canvas) => {
      // Optionally, resize the canvas here as needed
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 300; // Target width
      resizedCanvas.height = 300; // Target height
      ctx.drawImage(canvas, 0, 0, 300, 300);
      const base64image = resizedCanvas.toDataURL("image/png");
      setNewFileScreenShot(base64image);
      saveDataWithFilename(globalRefs, globalFileStates, globalState, base64image); // Pass base64image here
    });
  };

  // Save current data to server
  const saveCurrentDataSetClick = () => {
    setIsLoading(true);
    html2canvas(mapContainerRef.current, { scale: 1 }).then((canvas) => {
      // Optionally, resize the canvas here as needed
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 300; // Target width
      resizedCanvas.height = 300; // Target height
      ctx.drawImage(canvas, 0, 0, 300, 300);
      const base64image = resizedCanvas.toDataURL("image/png");
      setNewFileScreenShot(base64image);
      saveCurrentDataSet(globalRefs, globalFileStates, globalState, base64image);
    });
  };

  // Load data from server
  const closeLoadMenu = () => {
    setisMenuLoadClick(false);
  }

  // Load data from server
  const loadMenuClick = () => {
    setisMenuLoadClick((prevIsMenuLoadClick) => !prevIsMenuLoadClick);
  }

  // Load data from server
  const handleItemClick = (filename) => {
    setIsLoading(true);
    loadData(filename, globalRefs, globalFileStates, globalState);
  };

  // Auth clicked
  const authClicked = () => {
    setopenAuth(true);
    console.log('auth clicked');
  };

  // Close auth
  const closeAuth = () => {
    setopenAuth(false);
  }

  const closeControlsNav = () => {
    setIsControlsNavOpen(false);
  }

  // Landtype selection cancel on click
  const LandtypeSelectionCancelOnClick = () => {
    if (isSelectingLandtypes) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisSelectingLandtypes(false);
      setisLeftSubDrawerOpen(false);
    } else {
      if (isMobile) {
        resetLeftSubMenu();
        setisDraggableSubDrawerOpen(true);
        setisSelectingLandtypes(true);
      } else {
        resetLeftSubMenu();
        setisSelectingLandtypes(true);
        setisLeftSubDrawerOpen(true);
      }
    }
  }

  const PieChartselectionCancelOnClick = () => {
    if (isViewingPieChart) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisViewingPieChart(false);
      setisLeftSubDrawerOpen(false);
    } else {
      if (isMobile) {
        resetLeftSubMenu();
        setisDraggableSubDrawerOpen(true);
        setisViewingPieChart(true);
      } else {
        resetLeftSubMenu();
        setisViewingPieChart(true);
        setisLeftSubDrawerOpen(true);
        polygonCustomLayerRef.current.serEnablePieChart();
      }
    }
  }

  const handleEnlargePieButtonClick = () => {
    setisViewingLargePieChart(true);
    setisViewingPieChart(false);
    setisLeftSubDrawerOpen(false);
    polygonCustomLayerRef.current.resetfeatureOpacities(fmesh);
  }

  const handleClosePieButtonClick = () => {
    setisViewingLargePieChart(false);
    setisViewingPieChart(false);
    setisLeftSubDrawerOpen(false);
  }

  const ForecastCancelOnClick = () => {
    resetLeftSubMenu();
    setisViewingForecast(true);
    setisLeftSubDrawerOpen(true);
  }

  const ModifySelectionCancelOnClick = () => {
    if (isViewingForecast) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisViewingForecast(false);
      setisLeftSubDrawerOpen(false);
    } else {
      setisViewingForecast(true);
      resetLeftSubMenu();
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleModifyClick = () => {
    if (isModifying) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisModifying(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      setisModifying(true);
      beginDrawOnClick();
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleCompareClick = () => {
    if (isComparing) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisComparing(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      console.log("comparing");
      setisComparing(true);
      setisLeftSubDrawerOpen(true);
    }
  }

  const handleLandUseClick = () => {
    if (isLanduse) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setIsLanduse(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      console.log("landuse");
      setIsLanduse(true);
      setisLeftSubDrawerOpen(true);
      polygonCustomLayerRef.current.setEnableLandUse();
    }
  }

  const handleBenchmarkClick = () => {
    if (isBenchmarking) {
      resetLeftSubMenu();
      setisDraggableSubDrawerOpen(false);
      setisBenchmarking(false);
      setisLeftSubDrawerOpen(false);
    } else {
      resetLeftSubMenu();
      setisBenchmarking(true);
      console.log(isBenchmarking);
      polygonCustomLayerRef.current.setEnableBenchmarking();
      setisLeftSubDrawerOpen(true);
    }
  }

  // Begin draw
  const beginDrawOnClick = () => {
    setIsLoading(true);
    setisDrawing(true);
    setisLeftSubDrawerOpen(true);
    setTimeout(() => {
      initializeMapboxDraw(globalRefs, globalState);
      polygonCustomLayerRef.current.removeDrawFromScene();
    }, loading_timer_count);
  };

  // End draw and save
  const endDrawOnClick = () => {
    setIsLoading(true);
    setisDrawing(false);
    setisModifying(false);
    ResetMap(globalRefs, globalState);
    setisLeftSubDrawerOpen(false);
  };

  // End draw and cancel
  const endDrawCancelOnClick = () => {
    setIsLoading(true);
    setisDrawing(false);
    setisModifying(false);
    ResetMap(globalRefs, globalState);
    setisLeftSubDrawerOpen(false);
  };

  const handleLandtypePTagClick = (item) => {
    if (item === 'Reset') {
      polygonCustomLayerRef.current.setLandtype([]);
      setSelectedLandTypes([]);
      return;
    }
    setSelectedLandTypes(prevSelectedLandTypes => {
      if (prevSelectedLandTypes.includes(item)) {
        const newSelectedLandTypes = prevSelectedLandTypes.filter(type => type !== item);
        polygonCustomLayerRef.current.setLandtype(newSelectedLandTypes);
        return newSelectedLandTypes
      } else {
        const newSelectedLandTypes = [...prevSelectedLandTypes, item];
        polygonCustomLayerRef.current.setLandtype(newSelectedLandTypes);
        return newSelectedLandTypes;
      }
    });
  };

  // Reset left sub menu
  const resetLeftSubMenu = () => {
    setSelectedLandTypes([]);
    if (isDrawing) {
      setIsLoading(true);
      ResetMap(globalRefs, globalState);
      setisDrawing(false);
    }
    if (isSelectingLandtypes) {
      setisSelectingLandtypes(false);
      polygonCustomLayerRef.current.setLandtype([]);
      setSelectedLandTypes([]);
    }
    if (isViewingPieChart) {
      setisViewingPieChart(false);
    }
    if (piechartRef.current) {
      piechartRef.current.destroy();
    }
    if (isViewingForecast) {
      setisViewingForecast(false);
    }
    if (isModifying) {
      setisModifying(false);
    }
    if (isComparing) {
      setisComparing(false);
    }
    if (isLanduse) {
      setIsLanduse(false);
    }
    if (isBenchmarking) {
      setBenchmarkingData([]);
      setisBenchmarking(false);
      polygonCustomLayerRef.current.setDisableBenchmarking();
      polygonCustomLayerRef.current.resetfeatureOpacities(fmesh);
    }
    if (mapRef.current.hasControl(drawRef.current)) {
      mapRef.current.off('draw.create');
      mapRef.current.off('draw.delete');
      mapRef.current.removeControl(drawRef.current);
    }
    polygonCustomLayerRef.current.handleCloseClickedInfoCancel();
    polygonCustomLayerRef.current.setDisableLandUse();
    polygonCustomLayerRef.current.setDisablePieChart();
    polygonCustomLayerRef.current.setDisableBenchmarking();
  }

  const resetLeftSubMenuModifying = () => {
    if (isDrawing) {
      setIsLoading(true);
      ResetMap(globalRefs, globalState);
      setisDrawing(false);
    }
    if (isModifying) {
      setisModifying(false);
    }
    if (isComparing) {
      setisComparing(false);
    }
    if (isLanduse) {
      setIsLanduse(false);
    }
    if (isBenchmarking) {
      setBenchmarkingData([]);
      setisBenchmarking(false);
      polygonCustomLayerRef.current.setDisableBenchmarking();
      polygonCustomLayerRef.current.resetfeatureOpacities(fmesh);
    }
    if (mapRef.current.hasControl(drawRef.current)) {
      mapRef.current.off('draw.create');
      mapRef.current.off('draw.delete');
      mapRef.current.removeControl(drawRef.current);
    }
    polygonCustomLayerRef.current.handleCloseClickedInfoCancel();
  }

  const toggleDrawer = () => {
    setisLeftDrawerOpen((prevState) => !prevState);
    console.log("clicked")
  }

  const toggleLeftSubDrawer = () => {
    setisLeftSubDrawerOpen((prevState) => !prevState);
    resetLeftSubMenu();
  }

  const resetMetric = () => {
    if (loggedInToAPI) {
      ResetPolygonLayer(globalRefs, globalState);
    } else {
      ResetPolygonLayerPublic(globalRefs, globalState);
    }
  }

  const isStateUpdated = useRef(false);

  // Function to handle year selection change
  const handleYearChange = (event) => {
    const yearToSet = parseInt(event.target.value);
    console.log(event.target.value);
    setSelectedYearEditing(event.target.value);
    isStateUpdated.current = true;
  };

  const closeSettingsPanel = () => {
    setIsInSettings(false);
  }

  const openAboutPanel = () => {
    resetAboutPanels();
    setIsInAboutPanel(true);
  }

  const openPrivacyPanel = () => {
    resetAboutPanels();
    setIsInPrivacyPanel(true);
  }

  const openTacPanel = () => {
    resetAboutPanels();
    setIsInTacPanel(true);
  }

  const openInverstorsPanel = () => {
    resetAboutPanels();
    setIsInInvestorsPanel(true);
  }

  const openMobileSignIn = () => {
    setopenAuth(true);
  }

  const resetAboutPanels = () => {
    setIsInSettings(false);
    setIsInAboutPanel(false);
    setIsInPrivacyPanel(false);
    setIsInTacPanel(false);
    setIsInInvestorsPanel(false);
  }

  // Use useEffect to trigger ResetMap only if state has been updated synchronously
  useEffect(() => {
    if (isStateUpdated.current) {
      ResetMapYear(globalRefs, globalState);
      isStateUpdated.current = false; // Reset the flag
    }
  }, [selectedYearEditing]);

  useEffect(() => {
    if (mapLoaded) {
      if (loggedInToAPI) {
        mapRef.current.flyTo({
          center: [-0.9061603891426653, 54.121878725877096],
          zoom: 13,
          speed: 3, // Speed of the fly animation
        });
      } else {
        mapRef.current.flyTo({
          center: [-0.9061603891426653, 54.121878725877096],
          zoom: 5,
          speed: 1, // Speed of the fly animation
        });
      }
    }
  }, [loggedInToAPI, mapLoaded]);

  // Initialize map
  const initializeMap = () => {
    mapboxgl.accessToken = mapboxTokenRef.current;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/jevonmahoney/clye79kxt00oz01pmchxfdgvq',
      center: [-0.9061603891426653, 54.121878725877096],
      zoom: 5,
      pitch: 60,
      bearing: 0,
      preserveDrawingBuffer: true
    });
    mapRef.current.on('load', () => {
      if (mapRef.current.isStyleLoaded()) {
        // Perform actions or set states based on style load
        console.log('Map style has loaded!');
        setMapLoaded(true);
        setLoadedPercentage(20);
      }
    });
    mapRef.current.on('rotate', () => {
      setMapBearing(mapRef.current.getBearing());
    });
    mapRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true
      })
    );
    if (isMobile) {
      setisLeftDrawerOpen(false);
    }
  };

  // Initialize
  useEffect(() => {
    setLoadedPercentage(10);
    initializeMap();
    // fetchFilenames(globalRefs, globalFileStates, globalState);
  }, []);

  // 

  return (

    <div>

      <div className='top_menu'>
        <div className='title'>
          Logo
        </div>
        <div className='auth'>
          <MdAccountCircle />
        </div>
      </div>

      <MobileView>
        <DraggableSubDrawer
          isDraggableSubDrawerOpen={isDraggableSubDrawerOpen}
          setisDraggableSubDrawerOpen={setisDraggableSubDrawerOpen}
          isViewingPieChart={isViewingPieChart}
          longstandingGeoJSONRef={longstandingGeoJSONRef}
          selectedMetric={selectedMetric}
          isUKHabColoursActive={isUKHabColoursActive}
          handleLandtypePTagClick={handleLandtypePTagClick}
          polygonCustomLayerRef={polygonCustomLayerRef}
          piechartRef={piechartRef}
          handleEnlargePieButtonClick={handleEnlargePieButtonClick}
          isSelectingLandtypes={isSelectingLandtypes}
          selectedLandTypes={selectedLandTypes}
          uniqueTextureNamesArray={uniqueTextureNamesArray}
          selectedYearEditing={selectedYearEditing}
        />
        <DraggableDrawer
          PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
          LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
          ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
          handleCompareClick={handleCompareClick}
          handleLandUseClick={handleLandUseClick}
          handleBenchmarkClick={handleBenchmarkClick}
          isDraggableSubDrawerOpen={isDraggableSubDrawerOpen}
        />
      </MobileView>

      <LoadingSpinner
        isLoading={isLoading}
        loadedAmount={loadedAmount}
        setLoadedAmount={setLoadedAmount}
        setIsLoading={setIsLoading}
        loadedPercentage={loadedPercentage}
      />

      <TopMenu
        authClicked={authClicked}
        openAboutPanel={openAboutPanel}
        openPrivacyPanel={openPrivacyPanel}
        openTacPanel={openTacPanel}
        openInverstorsPanel={openInverstorsPanel}
        openMobileSignIn={openMobileSignIn}
      />

      <TopSubMenu
        openFilenameInputModal={openFilenameInputModal}
        saveCurrentDataSetClick={saveCurrentDataSetClick}
        loadMenuClick={loadMenuClick}
        isHasSaved={isHasSaved}
        savedFilename={savedFilename}
        isHasSavedCurrentData={isHasSavedCurrentData}
        loadedFileName={loadedFileName}
        isFilenameInputOpen={isFilenameInputOpen}
        closeFilenameInputModal={closeFilenameInputModal}
        newFilename={newFilename}
        handleFilenameInputChange={handleFilenameInputChange}
        saveDataWithFilenameClick={saveDataWithFilenameClick}
        isMenuLoadClick={isMenuLoadClick}
        closeLoadMenu={closeLoadMenu}
        filenames={filenames}
        handleItemClick={handleItemClick}
        mapboxTokenRef={mapboxTokenRef}
        globalRefs={globalRefs}
        globalState={globalState}
        setIsInAboutPanel={setIsInAboutPanel}
        setIsInInvestorsPanel={setIsInInvestorsPanel}
        setIsInPrivacyPanel={setIsInPrivacyPanel}
        setIsInTacPanel={setIsInTacPanel}
        isInAboutPanel={isInAboutPanel}
        isInInvestorsPanel={isInInvestorsPanel}
        isInPrivacyPanel={isInPrivacyPanel}
        isInTacPanel={isInTacPanel}
        resetAboutPanels={resetAboutPanels}
        isInSettings={isInSettings}
        mapbearing={mapbearing}
        loggedInToAPI={loggedInToAPI}
      />

      <LeftMenu
        isDrawing={isDrawing}
        isSelectingLandtypes={isSelectingLandtypes}
        LandtypeSelectionCancelOnClick={LandtypeSelectionCancelOnClick}
        setisSelectingLandtypes={setisSelectingLandtypes}
        beginDrawOnClick={beginDrawOnClick}
        endDrawOnClick={endDrawOnClick}
        endDrawCancelOnClick={endDrawCancelOnClick}
        toggleDrawer={toggleDrawer}
        isLeftDrawerOpen={isLeftDrawerOpen}
        isLeftSubDrawerOpen={isLeftSubDrawerOpen}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        resetMetric={resetMetric}
        PieChartselectionCancelOnClick={PieChartselectionCancelOnClick}
        ForecastCancelOnClick={ForecastCancelOnClick}
        ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
        selectedYearEditing={selectedYearEditing}
        setSelectedYearEditing={setSelectedYearEditing}
        isViewingPieChart={isViewingPieChart}
        isViewingForecast={isViewingForecast}
        isModifying={isModifying}
        handleYearChange={handleYearChange}
        handleModifyClick={handleModifyClick}
        isLanduse={isLanduse}
        handleLandUseClick={handleLandUseClick}
        isComparing={isComparing}
        handleCompareClick={handleCompareClick}
        isBenchmarking={isBenchmarking}
        handleBenchmarkClick={handleBenchmarkClick}
        globalState={globalState}
        globalRefs={globalRefs}
        loggedInToAPI={loggedInToAPI}
      />

      <LeftSubMenu
        isDrawing={isDrawing}
        beginDrawOnClick={beginDrawOnClick}
        endDrawOnClick={endDrawOnClick}
        endDrawCancelOnClick={endDrawCancelOnClick}
        isSelectingLandtypes={isSelectingLandtypes}
        toggleLeftSubDrawer={toggleLeftSubDrawer}
        isLeftSubDrawerOpen={isLeftSubDrawerOpen}
        handleLandtypePTagClick={handleLandtypePTagClick}
        selectedLandTypes={selectedLandTypes}
        isViewingPieChart={isViewingPieChart}
        longstandingGeoJSONRef={longstandingGeoJSONRef}
        originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
        isViewingForecast={isViewingForecast}
        selectedYearEditing={selectedYearEditing}
        setSelectedYearEditing={setSelectedYearEditing}
        ModifySelectionCancelOnClick={ModifySelectionCancelOnClick}
        isModifying={isModifying}
        isComparing={isComparing}
        isBenchmarking={isBenchmarking}
        handleYearChange={handleYearChange}
        handleModifyClick={handleModifyClick}
        handleCompareClick={handleCompareClick}
        handleBenchmarkClick={handleBenchmarkClick}
        selectedMetric={selectedMetric}
        benchmarkingData={benchmarkingData}
        uniqueTextureNamesArray={uniqueTextureNamesArray}
        isUKHabColoursActive={isUKHabColoursActive}
        handleLandUseClick={handleLandUseClick}
        isLanduse={isLanduse}
        polygonCustomLayerRef={polygonCustomLayerRef}
        isVisible={isVisible}
        dropdownhighlightedIndex={dropdownhighlightedIndex}
        setdropdownhighlightedIndex={setdropdownhighlightedIndex}
        setIsLanduse={setIsLanduse}
        Benchmarking={Benchmarking}
        benchmarkingVisible={benchmarkingVisible}
        piechartRef={piechartRef}
        handleEnlargePieButtonClick={handleEnlargePieButtonClick}
        setcomparingLandtypeSelectedOption={setcomparingLandtypeSelectedOption}
        resetLeftSubMenu={resetLeftSubMenu}
        setisLeftSubDrawerOpen={setisLeftSubDrawerOpen}
        storedfeaturecurrentlandtype={storedfeaturecurrentlandtype}
        setStoredFeatureCurrentLandtype={setStoredFeatureCurrentLandtype}
        temporaryGeoJSONIntendedForSaving={temporaryGeoJSONIntendedForSaving}
        setTemporaryGeoJSONIntendedForSaving={setTemporaryGeoJSONIntendedForSaving}
      />

      <Login
        openAuth={openAuth}
        closeAuth={closeAuth}
        setIsInSettings={setIsInSettings}
        isInSettings={isInSettings}
        isInAboutPanel={isInAboutPanel}
        isInInvestorsPanel={isInInvestorsPanel}
        isInPrivacyPanel={isInPrivacyPanel}
        isInTacPanel={isInTacPanel}
        setIsInAboutPanel={setIsInAboutPanel}
        setIsInInvestorsPanel={setIsInInvestorsPanel}
        setIsInPrivacyPanel={setIsInPrivacyPanel}
        setIsInTacPanel={setIsInTacPanel}
        loggedInToAPI={loggedInToAPI}
        setLoggedInToAPI={setLoggedInToAPI}
      />

      <ClickedInfo
        isVisible={isVisible}
        polygonCustomLayerRef={polygonCustomLayerRef}
        clickedInfo={clickedInfo}
        clickedInfoHeader={clickedInfoHeader}
        clickedInfoHeaderMobile={clickedInfoHeaderMobile}
        globalState={globalState}
        foundFeature={foundFeature}
        longstandingGeoJSONRef={longstandingGeoJSONRef}
      />

      {isComparing ? (
        <ComparePanel
          longstandingGeoJSONRef={longstandingGeoJSONRef}
          originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
          selectedMetric={selectedMetric}
          comparingLandtypeSelectedOption={comparingLandtypeSelectedOption}
          selectedYearEditing={selectedYearEditing}
        />
      ) : (
        <div>
          <GeneralNav
            globalRefs={globalRefs}
            globalState={globalState}
            setIsUKHabColoursActive={setIsUKHabColoursActive}
            selectedYearEditing={selectedYearEditing}
          />
          <ControlsNav
            globalRefs={globalRefs}
            globalState={globalState}
            setIsUKHabColoursActive={setIsUKHabColoursActive}
            selectedYearEditing={selectedYearEditing}
            closeControlsNav={closeControlsNav}
            isControlsNavOpen={isControlsNavOpen}
            setIsControlsNavOpen={setIsControlsNavOpen}
          />
        </div>
      )}

      {is2DViewActive && !isComparing ? (
        <UKHABcoloursTab
          globalRefs={globalRefs}
          globalState={globalState}
          setIsLoading={setIsLoading}
          isUKHabColoursActive={isUKHabColoursActive}
          setIsUKHabColoursActive={setIsUKHabColoursActive}
        />
      ) : (
        null
      )
      }

      {isViewingLargePieChart && !isComparing ? (
        <LargePieChart
          geojson={longstandingGeoJSONRef.current}
          isViewingPieChart={isViewingPieChart}
          selectedMetric={selectedMetric}
          isUKHabColoursActive={isUKHabColoursActive}
          polygonCustomLayerRef={polygonCustomLayerRef}
          piechartRef={piechartRef}
          handleEnlargePieButtonClick={handleEnlargePieButtonClick}
          handleClosePieButtonClick={handleClosePieButtonClick}
          selectedYearEditing={selectedYearEditing}
        />
      ) : (
        null
      )}

      {/* {isModifying &&
        <ModifyPanel
          endDrawOnClick={endDrawOnClick}
          endDrawCancelOnClick={endDrawCancelOnClick}
          selectedYearEditing={selectedYearEditing}
        />} */}

      <FilenameInputModal
        isFilenameInputOpen={isFilenameInputOpen}
        closeFilenameInputModal={closeFilenameInputModal}
        newFilename={newFilename}
        handleFilenameInputChange={handleFilenameInputChange}
        saveDataWithFilenameClick={saveDataWithFilenameClick}
        globalState={globalState}
        globalFileStates={globalFileStates}
        globalRefs={globalRefs}
        ResetMap={ResetMap}
      />

      {isInSettings ? (
        <SettingsPanel
          isComparing={isComparing}
          handlesettingsClose={closeSettingsPanel}
          longstandingGeoJSONRef={longstandingGeoJSONRef}
          originalGeoJSONforComparingRef={originalGeoJSONforComparingRef}
          selectedMetric={selectedMetric}
        />
      ) : (
        null
      )}

      {isInAboutPanel ? (
        <AboutPanel
        />
      ) : (
        null
      )}

      {isInPrivacyPanel ? (
        <PrivacyPanel
        />
      ) : (
        null
      )}

      {isInTacPanel ? (
        <TacPanel
        />
      ) : (
        null
      )}

      {isInInvestorsPanel ? (
        <InvestorsPanel
        />
      ) : (
        null
      )}

      <LoadMenu
        isMenuLoadClick={isMenuLoadClick}
        closeLoadMenu={closeLoadMenu}
        files={filenames}
        handleItemClick={handleItemClick}
        globalRefs={globalRefs}
        globalState={globalState}
        ResetMapLoadedData={ResetMapLoadedData}
        mapLoaded={mapLoaded}
        loggedInToAPI={loggedInToAPI}
        setLoggedInToAPI={setLoggedInToAPI}
        ResetPolygonLayer={ResetPolygonLayer}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        setLoadedPercentage={setLoadedPercentage}
        ResetPolygonLayerPublic={ResetPolygonLayerPublic}
      />

      <div ref={mapContainerRef} id='mapContainerRef' className='map-container'></div>

    </div>

  );

};

export default HomeApp;
