import React from 'react';
import { HiClipboardList } from "react-icons/hi";

const LandTypes = ({ LandtypeSelectionCancelOnClick, isSelectingLandtypes }) => {

  return (
    <div className={`land_types ${isSelectingLandtypes ? 'selected' : ''}`} onClick={LandtypeSelectionCancelOnClick}>
      <button id='begin_draw_button'> <HiClipboardList className="leftMenuIcons"/> Land Key</button>
    </div>
  )
};

export default LandTypes;
