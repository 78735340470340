import React, { useEffect, useState } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

const FilenameInputModal = ({
  isFilenameInputOpen,
  closeFilenameInputModal,
  newFilename,
  handleFilenameInputChange,
  saveDataWithFilenameClick,
  globalState,
  globalFileStates,
  globalRefs,
  ResetMap
}) => {

  let mapSavedWithFilenameSuccess = globalState.mapSavedWithFilenameSuccess;
  let setMapSavedWithFilenameSuccess = globalState.setMapSavedWithFilenameSuccess;

  let setIsFilenameInputOpen = globalFileStates.setIsFilenameInputOpen;

  const [mapSavedWithFilenameProgress, setMapSavedWithFilenameProgress] = useState(false);

  useEffect(() => {
    if (mapSavedWithFilenameSuccess) {
      setTimeout(() => {
        setMapSavedWithFilenameSuccess(false);
        setIsFilenameInputOpen(false);
        ResetMap(globalRefs, globalState);
      }, 2000);
    }
  }, [mapSavedWithFilenameSuccess]);

  const saveDataWithFilenameClickInner = () => {
    saveDataWithFilenameClick();
    setMapSavedWithFilenameProgress(true);
  }

  return (
    isFilenameInputOpen && (
      <div className='filename-input-modal'>
        {mapSavedWithFilenameProgress ? (
          <div className='modal-content'>
            <p className='modal-contentImportant'>Saving map</p>

            <div className='modal-content__loader'>
              <div className='modal-content__loader__spinner'></div>
              <PropagateLoader />
            </div>

          </div>
        ) : (
          <div>
            <div className='modal-content'>
              <p>Save As...</p>
            </div>
            <input
              type='text'
              value={newFilename}
              onChange={handleFilenameInputChange}
              className='filename-input-modal__input'
              placeholder='Enter filename here...'
            />
            <div className='filename-input-modal__buttons'>
              <div className='closeSaveWithFilename' onClick={closeFilenameInputModal}>Cancel</div>
              <button className='saveWithFileName' onClick={saveDataWithFilenameClickInner}>Save</button>
            </div>
          </div>
        )
        }
        {
          mapSavedWithFilenameSuccess && (
            <div className='modal-content'>
              <p className='modal-contentImportant'>Map saved as {newFilename}</p>
            </div>
          )
        }
      </div >
    )
  );
};

export default FilenameInputModal;