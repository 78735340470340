import React, { useEffect, useState } from 'react';

const MetricDropdown = ({ 
  metricOptions, 
  resetMetric, 
  setSelectedMetric,
  selectedMetric,
  globalState,
  publicMetricOptions
}) => {

  const [clicked, setClicked] = useState(false);

  let loggedInToAPI = globalState.loggedInToAPI;

  const handleOptionClick = (selectedValue) => {
    setClicked(true);
    setSelectedMetric(selectedValue);
  };

  useEffect(() => {
    if (clicked) {
      resetMetric();
      setClicked(false);
    }
  }, [selectedMetric, clicked, resetMetric]);

  return (
    <div className='select-container'>
      {loggedInToAPI ? (
      <>
      <select className='metricSelectionModify' onChange={(e) => handleOptionClick(e.target.value)}>
        {metricOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="select-arrow"></div>
      </>
      ) : (
      <select className='metricSelectionModify' onChange={(e) => handleOptionClick(e.target.value)}>
        {publicMetricOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
        </select>
      )}
    </div>
  );
};

export default MetricDropdown;


